import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "@reach/router";
import { useStyles } from "./styles";
import jwtDecode from "jwt-decode";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Collapse,
} from "@material-ui/core";
import {
  Dashboard,
  LocalHospital,
  Notifications,
  Help,
  ChromeReaderMode,
} from "@material-ui/icons";
import { menuOptionsAction } from "../../actions/menuOptionsActions";

import { Can } from "../../authorization/abilities";

const NavLinkItem = ({ children, open, className, group, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOptionClickeable = (opt) => {
    dispatch(menuOptionsAction(opt));
  };

  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        // the object returned here is passed to the
        // anchor element's props
        return {
          style: {
            width: 180,
            paddingRight: 11,
          },
          className: isCurrent ? className : classes.deactiveNavlinkItem,
        };
      }}
      onClick={() => handleOptionClickeable(group)}
    >
      <ListItem>
        <div className="hover-effect" style={{ width: 0 }} />
        {children}
      </ListItem>
    </Link>
  );
};

const NavLink = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOptionClickeable = (opt) => {
    dispatch(menuOptionsAction(opt));
  };

  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        return {
          className: isCurrent ? props.className : classes.deactiveNavlink,
        };
      }}
      onClick={() => handleOptionClickeable(props.group)}
    />
  );
};

const CollapsibleItem = ({
  children,
  onClick,
  menuOpen,
  open,
  icon,
  title,
  className,
}) => {
  const classes = useStyles();
  const config = useSelector((state) => state.userConfig.data);

  return (
    // icono + titulo
    <Grid className={open ? classes.optionOpen : null}>
      <ListItem className={className} button onClick={onClick}>
        {Object.keys(config).length !== 0 && (
          <Grid
            className="line"
            style={{
              borderLeft: `4px solid ${
                open
                  ? config.theme.secondary.variant1
                  : config.theme.primary.variant1
              }`,
            }}
          />
        )}
        <ListItemIcon className={open ? classes.iconActive : classes.icon}>
          {icon}
        </ListItemIcon>
        <ListItemText
          className={open ? classes.activeLink : classes.deactiveLink}
          primary={title}
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <div
            style={{
              transition: "all 225ms cubic-bezier(0.4, 0, 0.6, 1)",
              paddingLeft: menuOpen ? 42 : 0,
              paddingBottom: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {children}
          </div>
        </List>
      </Collapse>
    </Grid>
  );
};

const SideMenu = ({ menuOpen, handleGetOptionMenu }) => {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const {
    user: {
      permissions: { Backoffice },
    },
  } = jwtDecode(token);

  const [groupOpened, setGroupOpened] = useState("");

  const handleOpenGroup = (group) => (evt) => {
    group === groupOpened ? setGroupOpened("") : setGroupOpened(group);
  };

  const handleGetOption = (name) => {
    handleGetOptionMenu(name);
  };

  return (
    <>
      <List className={classes.navList}>
        <Can do="read" on="Dashboard">
          <NavLink
            group="dashboard"
            to="dashboard"
            className={classes.activeLink}
          >
            <ListItem>
              <div className="hover-effect" style={{ width: 0 }} />
              <ListItemIcon>
                <Dashboard className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItem>
          </NavLink>
        </Can>

        <Can do="read" on="MedicalNetWork">
          <NavLink
            group="medical-network"
            to="medical-network"
            className={classes.activeLink}
          >
            <ListItem>
              <div className="hover-effect" style={{ width: 0 }} />
              <ListItemIcon>
                <LocalHospital className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Red Médica" />
            </ListItem>
          </NavLink>
        </Can>
        <Can do="read" on="Notifications">
          <NavLink
            group="notifications"
            to="notifications"
            className={classes.activeLink}
          >
            <ListItem>
              <div className="hover-effect" style={{ width: 0 }} />
              <ListItemIcon>
                <Notifications className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Notificaciones" />
            </ListItem>
          </NavLink>
        </Can>

        <Can do="read" on="FAQ">
          <NavLink group="faq" to="faq" className={classes.activeLink}>
            <ListItem>
              <div className="hover-effect" style={{ width: 0 }} />
              <ListItemIcon>
                <Help className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Ayuda" />
            </ListItem>
          </NavLink>
        </Can>
        <Can do="read" on="News">
          <NavLink group="news" to="news" className={classes.activeLink}>
            <ListItem className="height-icon">
              <div className="hover-effect" style={{ width: 0 }} />
              <ListItemIcon>
                <ChromeReaderMode className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Noticias" />
            </ListItem>
          </NavLink>
        </Can>
      </List>
    </>
  );
};

export default SideMenu;
