import React, { Fragment, useState } from "react";
import { Grid, List, ListItem, Typography } from "@material-ui/core";
import { NotificationsOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import { readNotification } from "../../actions/userEventsActions";
import { useStyles } from "./styles";
import StyledBadge from "./styledBadge";
import DrawerDetailNotification from "./drawers/drawerDetailNotification";

const NotificationList = ({ token, notifications, contained }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedMessage, setSelectedMessage] = useState({});

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleReadMessage = async (noti) => {
    setSelectedMessage(noti);
    // setOpenNotiReader(true);
    handleShowDrawer(true);
  };

  const handleRead = async (noti) => {
    if (!noti.read) {
      await dispatch(readNotification(token, noti._id));
    }
  };

  const handleShowDrawer = (opt) => {
    setOpenDrawer(opt);
  };

  return (
    <Fragment>
      <List className={classes.list}>
        {notifications.map((noti, i) => (
          <ListItem
            onClick={() => {
              handleReadMessage(noti);
            }}
            button
            className={clsx(classes.notificationListItem, {
              [classes.unread]: !noti.read,
            })}
            key={`notification__item__${i}`}
          >
            <Grid item container alignItems="center" style={{ flex: 1 }}>
              {!noti.read ? (
                <StyledBadge
                  variant="dot"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  overlap="circular"
                  color="secondary"
                >
                  <NotificationsOutlined style={{ color: "#333333" }} />
                </StyledBadge>
              ) : (
                <NotificationsOutlined style={{ color: "#828282" }} />
              )}
              <Typography
                className={clsx({
                  [classes.truncateText]: contained,
                })}
                component="span"
                style={{
                  marginLeft: 20,
                  fontWeight: noti.read ? 400 : 600,
                  letterSpacing: 0.15,
                  fontSize: 16,
                }}
              >
                {noti.message.title}
              </Typography>
            </Grid>
            {!contained && (
              <Grid
                item
                className={classes.notificationListItemSecondary}
                style={{
                  color: noti.read ? "#C4C4C4" : "#000",
                }}
              >
                <Typography className="hora">
                  {moment(noti.sentAt).format("hh:mm")}
                </Typography>
                <Typography className="fecha">
                  {moment(noti.sentAt).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
            )}
          </ListItem>
        ))}
      </List>
      {/* <NotificationRead
                handleAccept={handleCloseReader}
                open={openNotiReader}
                message={selectedMessage}
            /> */}
      <DrawerDetailNotification
        open={openDrawer}
        close={() => handleShowDrawer(false)}
        notification={selectedMessage}
        handleRead={handleRead}
      />
    </Fragment>
  );
};

export default NotificationList;
