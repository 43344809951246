import React from "react";
import {
  Snackbar,
  SnackbarContent,
  Slide,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { hideSuccess } from "../../actions/notificationActions";

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[500],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const MessageSuccess = ({ type }) => {
  const classes = useStyles();
  const notifications = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideSuccess());
  };

  const getLabelByType = (_type) => {
    switch (_type) {
      case "product":
        return "El producto";
      case "quotation":
        return "La pantalla de cotizacion";
      case "insurance":
        return "El tipo de seguro";
      case "module":
        return "El modulo";
      case "userEvents":
        return "han sido guardados correctamente";
      default:
        // console.log('Invalid type!');
        break;
    }
  };

  return (
    <Snackbar
      open={notifications.success}
      onClose={handleClose}
      autoHideDuration={3000}
      TransitionComponent={SlideTransition}
    >
      <SnackbarContent
        className={classes.success}
        message={
          <span className={classes.message}>
            <CheckCircleIcon
              className={`${classes.icon} ${classes.iconVariant}`}
            />
            {notifications.msg
              ? notifications.msg
              : `${getLabelByType(type)} ha sido guardado correctamente`}
          </span>
        }
        action={[
          <IconButton key="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default MessageSuccess;
