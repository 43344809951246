import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { RenderTextFieldRow } from '../quoter/quoteInputs/index';
import { useStyles } from './styles';
import { IconButton, Menu, MenuItem, withStyles } from '@material-ui/core';
import { Delete, Edit, MoreVert } from '@material-ui/icons';
import formatNumber from '../../utils/formatNumber';
import { Grid } from '@material-ui/core';

const validate = (values) => {
    const errors = {};
    const requiredFields = [];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

export function AddedRowForm({ fields, formRow, widthField = 4 }) {
    const classes = useStyles();

    const parseNumber = (number) => {
        const parsed = parseFloat(
            number.replaceAll('.', '').replace(',', '.')
        ).toFixed(2);
        return formatNumber.new(parsed, '$');
    };

    return (
        <Grid container item xs={12} md={12} className={classes.itemContainer}>
            <Grid container direction="row">
                {fields.map((field, j) => (
                    <Grid
                        key={j}
                        item
                        xs={12}
                        md={widthField}
                        style={{ padding: 8, position: 'relative' }}
                    >
                        <Field
                            name={field.name}
                            label={field.label}
                            component={RenderTextFieldRow}
                            custom={{ tabIndex: 0 }}
                            classes={classes.fontSizeLabel}
                            defaultValue={
                                field.mask === 'Moneda'
                                    ? parseNumber(formRow[field.name])
                                    : formRow[field.name]
                            }
                            field
                            disabled={true}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default reduxForm({
    form: 'addedRowForm',
    validate
})(AddedRowForm);

const StyledMenu = withStyles(() => ({
    list: {
        padding: 0
    }
}))(Menu);

export const CustomMenu = ({ id, handleDelete, handleUpdate }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton color="primary" onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <StyledMenu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={(evt) => {
                        evt.stopPropagation();
                        handleDelete();
                        handleClose();
                    }}
                >
                    <Delete color="primary" /> Eliminar
                </MenuItem>
                <MenuItem
                    onClick={(evt) => {
                        evt.stopPropagation();
                        handleUpdate();
                        handleClose();
                    }}
                >
                    <Edit color="primary" /> Editar
                </MenuItem>
            </StyledMenu>
        </>
    );
};
