import React from 'react';
import { Link } from '@reach/router';
import { Breadcrumbs, Grid } from '@material-ui/core';
import Wrapper from '../../components/sinisterPolicy/Wrapper';
function SinisterPolicy({ children }) {
    return (
        <Grid item xs={12}>
            <div style={{ marginBottom: 16, padding: 16 }}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link to="/dashboard">Home</Link>
                    <Link to="/sinister">Alta de siniestros</Link>
                </Breadcrumbs>
            </div>
            <Wrapper>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        {children}
                    </Grid>
                </Grid>
            </Wrapper>
        </Grid>
    );
}

export default SinisterPolicy;
