import axios from "axios";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { getDataToken, getToken } from "../utils/index";
import { showSuccess } from "../actions/notificationActions";
import { showModal } from "../actions/modalActions";
import { authUrlApi, exceptionCodeResponse } from "../utils/urls";

export const getDataAgentDocument = createAsyncAction(
  "GET_AGENT_DOCUMENT_INFO",
  async (token = null, dispatch) => {
    const userData = getDataToken(token);
    const headers = { Authorization: `Bearer ${token}` };
    const response = axios
      .get(`${authUrlApi}/agentDocument/${userData.user._id}`, { headers })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        dispatch(showModal(true));
        console.log(error);
      });
    return response;
  }
);

export const createDataAgentDocument = createAsyncAction(
  "CREATE_AGENT_DOCUMENT_INFO",
  async (body, dispatch) => {
    const token = getToken();
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.post(`${authUrlApi}/agentDocument`, body, {
        headers,
      });
      console.log("RESPONDIO");
      dispatch(showModal(false));
      return response;
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateDataAgentDocument = createAsyncAction(
  "UPDATE_AGENT_DOCUMENT_INFO",
  async (body, dispatch) => {
    const token = getToken();
    const headers = { Authorization: `Bearer ${token}` };
    const response = axios
      .put(`${authUrlApi}/agentDocument/update`, body, { headers })
      .then(({ data }) => {
        dispatch(showSuccess());
        return data;
      })
      .catch((error) => exceptionCodeResponse(error));
    return response;
  }
);
