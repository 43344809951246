import React, { useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import {
    RenderTextFieldRow,
    RenderSearchSelectField,
    RenderTextFieldDisabled,
    RenderDateField,
    renderCheckbox,
    RenderDecimalField
} from '../quoter/quoteInputs/index';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

const validate = (values) => {
    const errors = {};
    const requiredFields = [];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

export function NewRowForm({
    fields,
    errorsInputs,
    formRow,
    setFormRow,
    widthField = 4
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleChangeCustomInput = (name, e) => {
        let fromResult = {
            ...formRow,
            [name]: e
        };
        fields.forEach((field) => {
            if (field.dependsOn && field.dependsOn.includes(name)) {
                dispatch(change('newFormRow', field.name, ''));
                fromResult = {
                    ...fromResult,
                    [field.name]: ''
                };
            }
        });
        setFormRow({ ...fromResult });
    };

    return (
        <Grid container item xs={12} md={12} className={classes.itemContainer}>
            <Grid container direction="row">
                {fields.map((field, j) => (
                    <Grid
                        key={j}
                        item
                        xs={12}
                        md={widthField}
                        style={{ padding: 8, position: 'relative' }}
                    >
                        {field.controlType === 'chip' ? (
                            <>
                                <label className={classes.styledLabelInput}>
                                    {field.label}
                                </label>
                                <Field
                                    name={field.name}
                                    label={field.label}
                                    component={RenderSearchSelectField}
                                    custom={{ tabIndex: 6 }}
                                    classes={classes.fontSizeLabel}
                                    fieldData={field}
                                    defaultValue={formRow[field.name]}
                                    errorInput={errorsInputs[field.name]}
                                    handleChangeSelect={handleChangeCustomInput}
                                />
                            </>
                        ) : field.controlType === 'input birthdate' ? (
                            {
                                /* <Field
                                name={field.name}
                                label={field.label}
                                component={RenderDateField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                fieldData={field}
                                errorInput={errorsInputs[field.name]}
                                defaultValue={formRow[field.name]}
                                handleChangeDate={handleChangeCustomInput}
                            /> */
                            }
                        ) : field.controlType === 'input text' &&
                          field.mask === 'Moneda' ? (
                            <Field
                                name={field.name}
                                label={field.label}
                                classes={classes.fontSizeLabel}
                                custom={{ tabIndex: 6 }}
                                defaultValue={formRow[field.name]}
                                errorInput={errorsInputs[field.name]}
                                component={RenderDecimalField}
                                handleChange={handleChangeCustomInput}
                            />
                        ) : field.controlType === 'input autocomplete' ||
                          field.mask === 'Moneda' ? (
                            <Field
                                name={field.name}
                                label={field.label}
                                component={RenderTextFieldDisabled}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                errorInput={errorsInputs[field.name]}
                                defaultValue={formRow[field.name]}
                                onChange={handleChangeCustomInput}
                                field
                            />
                        ) : field.controlType === 'custom' ? (
                            <Field
                                name={field.name}
                                label={field.label}
                                component={renderCheckbox}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                errorInput={errorsInputs[field.name]}
                                defaultValue={formRow[field.name]}
                                onChange={handleChangeCustomInput}
                            />
                        ) : (
                            <Field
                                name={field.name}
                                label={field.label}
                                component={RenderTextFieldRow}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                errorInput={errorsInputs[field.name]}
                                defaultValue={formRow[field.name]}
                                handleChange={handleChangeCustomInput}
                                field
                            />
                        )}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default reduxForm({
    form: 'newRowForm',
    validate
})(NewRowForm);
