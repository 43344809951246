import React from 'react';
import { navigate } from '@reach/router';
import AdvanceLineSinister from '../../../components/sinisterPolicy/AdvanceLineSinister';

function AdvanceLineSinisterComponent() {
    const handleChangeDetailSinister = (url) => {
        navigate(url);
    };

    return (
        <>
            <AdvanceLineSinister />
        </>
    );
}

export default AdvanceLineSinisterComponent;
