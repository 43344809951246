import React from "react";
import {
  Card,
  CardContent,
  Grid,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { cardInsured } from "./style";
import { general } from "../../../../utils/icons";
import { truncateText } from "../../../../utils";
import { Skeleton } from "@material-ui/lab";
import { INSURED_CLIENT } from "../../../../utils/urls";

const getStatus = (date, defaultStatus) => {
  console.log(
    moment(moment(new Date()).format("YYYY-MM-DD")).isAfter(
      moment(date).format("YYYY-MM-DD")
    )
  );
  if (
    moment(moment(new Date()).format("YYYY-MM-DD")).isAfter(
      moment(date).format("YYYY-MM-DD")
    )
  ) {
    return "VENCIDA";
  }
  return "Default";
};

const CardInsured = ({
  risktype = null,
  policy,
  loading,
  handleOpenDrawer,
  isHistory = false,
  selected = false,
}) => {
  const statusMatch = {
    PENDIENTE: "#219653",
    VENCIDA: "#EB5757",
    ACTIVA: "#6FCF97",
    DEFAULT: "#000000",
  };

  const estado = `${
    INSURED_CLIENT === "LIBRA"
      ? getStatus(policy?.fecha_fin, policy?.estado)
      : policy?.estado || "default"
  }`.toUpperCase();
  const classes = cardInsured({
    colorStatus: statusMatch[estado] || null,
  });

  const capitalize = (word) =>
    word.replace(/\w\S*/g, (w) =>
      w.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())
    );

  return (
    <Card
      classes={{
        root: INSURED_CLIENT === "LIBRA" ? classes.rootLibra : classes.root,
      }}
      className={selected ? classes.selected : ""}
      onClick={handleOpenDrawer}
    >
      <CardContent className={classes.content}>
        <div className={classes.header}>
          <Grid container justifyContent="space-between">
            <div className={classes.status}>
              <>
                <SvgIcon>{general.oval}</SvgIcon>
                {INSURED_CLIENT !== "LIBRA" && (
                  <Typography>
                    {loading ? <Skeleton width="30%" /> : estado}
                  </Typography>
                )}
              </>
            </div>
            {!loading && risktype?.images?.insured ? (
              <img
                className={classes.icon}
                alt={risktype?.description || "icon"}
                src={risktype?.images?.insured}
              />
            ) : (
              <Skeleton variant="circle" width={"10%"} />
            )}
          </Grid>
        </div>
        <Grid container className={classes.text}>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              {loading ? <Skeleton width="90%" /> : policy.descripcion_item}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.policy}>
              {loading ? (
                <Skeleton width="50%" />
              ) : (
                `Póliza: ${policy.nro_poliza}`
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.ramo}>
              {loading ? (
                <Skeleton width="50%" />
              ) : (
                `${
                  INSURED_CLIENT === "LIBRA"
                    ? truncateText(
                        `${
                          risktype &&
                          risktype.description &&
                          capitalize(risktype.description)
                        }`,
                        21
                      )
                    : truncateText(
                        `${policy?.ramo && capitalize(policy?.ramo)}`,
                        21
                      )
                }`
              )}
            </Typography>
          </Grid>

          {isHistory ? (
            <Grid item xs={12}>
              <Typography
                className={clsx({
                  [classes.dueDate]: true,
                  [classes.stateColor]: estado === "VENCIDA",
                })}
              >
                {loading ? <Skeleton width="50%" /> : `Vigencia:`}
                {loading ? (
                  <Skeleton width="70%" />
                ) : (
                  `${moment(policy?.fecha_inicio).format(
                    "DD/MM/YYYY"
                  )} - ${moment(policy?.fecha_fin).format("DD/MM/YYYY")}`
                )}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography
                className={clsx({
                  [classes.dueDate]: true,
                  [classes.stateColor]:
                    policy?.proxima_cuota_estado === "VENCIDA",
                })}
              >
                {loading ? <Skeleton width="50%" /> : `Próxima renovación:`}
                {loading ? (
                  <Skeleton width="70%" />
                ) : policy?.fecha_fin ? (
                  moment(policy.fecha_fin).format("DD/MM/YYYY")
                ) : (
                  "N/A"
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardInsured;
