import moment from "moment";
import { repositoryCode } from './urls';

const Utils = {
    arrayMove: (array, from, to) => {
        const tempArray = array.slice();
        const startIndex = to < 0 ? tempArray.length + to : to;
        const item = tempArray.splice(from, 1)[0];
        tempArray.splice(startIndex, 0, item);
        return tempArray;
    },
    getObjectByType: (type, options) => {
        switch (type) {
            case "string":
                return { values: options.join(",") };
            case "numeric":
                return {
                    min: options[0],
                    max: options[1],
                    preffix: options[2],
                    suffix: options[3],
                };
            case "bool":
                return {
                    affirmativeLabel: options[0],
                    negativeLabel: options[1],
                };
            default:
                console.log("Invalid type!");
                break;
        }
    },
    getUploadedFileDimensions: (file) =>
        new Promise((resolve, reject) => {
            try {
                const img = new Image();

                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;

                    window.URL.revokeObjectURL(img.src);

                    return resolve({ width, height });
                };

                img.src = window.URL.createObjectURL(file);
            } catch (exception) {
                return reject(exception);
            }
        }),
    dateLocaleTime: (date) => {
        const createdAt = new Date(date);
        return `${createdAt.toLocaleDateString()} ${createdAt.toLocaleTimeString()}`;
    },
    dateToFormatUTC: (value, format) =>
        value ? moment(value).utc().format(format) : "",
    dateToFormat: (value, format) => (value ? moment(value).format(format) : ""),
    dateBetween2Dates: (date1, date2, type = "minutes") =>
        moment(date2).diff(moment(date1), type),
    showDiffTimes: (date1, date2) => {
        let diff = Utils.dateBetween2Dates(date1, date2, "minutes");
        let type = "minutos";
        let newDiff = 0;

        if (diff > 59) {
            newDiff = Utils.dateBetween2Dates(date1, date2, "hours");
            type = "horas";
            if (newDiff > 23) {
                type = "dias";
                newDiff = Utils.dateBetween2Dates(date1, date2, "days");
            }
            diff = newDiff;
        }

        return `${diff} ${type}`;
    },
    isEmptyObject: (obj) => {
        return Object.keys(obj).length === 0;
    },
    capitalize: (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    slugify: (string) => {
        const a =
            "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
        const b =
            "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
        const p = new RegExp(a.split("").join("|"), "g");

        let newSlug = string
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace spaces with -
            .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, "-and-") // Replace & with 'and'
            .replace(/[^\w-]+/g, "") // Remove all non-word characters
            .replace(/-+/g, "-") // Replace multiple - with single -
            .replace(/^-+/, "") // Trim - from start of text
            .replace(/-+$/, ""); // Trim - from end of text

        // This is to avoid a possible repeat slug at some point
        const randomId = Math.floor(Math.random() * 10000) + 100;
        newSlug = `${newSlug}-${randomId}`;

        return newSlug;
    },
    getBriefText: (data) => {
        if (!data) {
            return "";
        }

        let textWithoutHTML = data.replace(/<[^>]*>?/gm, "3e3");
        textWithoutHTML = textWithoutHTML.replace(/3e3/gm, ""); //fix space between words
        textWithoutHTML = textWithoutHTML.split("&nbsp;").join(" ");
        //textWithoutHTML = textWithoutHTML.substring(0, 300) //brief text

        return `${textWithoutHTML}...`;
    },
    formatCoin: (num, decimals = true) => {
        if (num === null || num === undefined) return;

        return num
            .toFixed(decimals ? 2 : 0)
            .replace(",", ".")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    openFile: (url = false) => {
        if (url) {
            window.open(url, "_blank");
        }
    },
    validateFile: (file) => {
        let errors = null;
        const ext = /(\.jpg|\.jpeg|\.gif|\.png|\.doc|\.docx|\.xls|\.xlsx|\.csv|\.txt|\.pdf)$/i;

        if (file && file.size > 2097152) {
            errors = "Supera el maximo permitido (2MB max)";
        }
        if (file && !ext.exec(file.name)) {
            errors = "Archivo no soportado";
        }

        return errors;
    },
    getAtribute: (Id, atribute, source) => {
        let value = "";
        if (source.data.length && Id) {
            value = source.data.find((item) => item._id === Id);
            if (value) {
                return value[atribute];
            }
        }
        console.log("getAtribute: ", value, Id, atribute, source);
        return value;
    },
    getUrlSplit: (name) => {
        const arr = [];
        const spl = name.split('/');
        const isAws = name.includes('amazon');
        const type = isAws || repositoryCode === 8 ? { start: 3, end: 6 } : { start: 4, end: 7 };

        for (let i = type.start; i <= type.end; i++) {
            const el = spl[i];
            arr.push(el);
        }

        return arr.join('/')
    },
    delimitText: (source, size) => {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    },
    isArray: (value) => {
        return Array.isArray(value);
    }
};

export default Utils;
