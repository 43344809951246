import React from "react";
import {
  Snackbar,
  SnackbarContent,
  Slide,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { hideWarning } from "../../actions/notificationActions";

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[500],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const MessageWarning = () => {
  const classes = useStyles();
  const notifications = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideWarning());
  };

  return (
    <Snackbar
      open={notifications.warning}
      onClose={handleClose}
      autoHideDuration={3000}
      TransitionComponent={SlideTransition}
    >
      <SnackbarContent
        className={classes.warning}
        message={
          <span className={classes.message}>
            <WarningIcon className={`${classes.icon} ${classes.iconVariant}`} />
            {notifications.warning && notifications.warning}
          </span>
        }
        action={[
          <IconButton key="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default MessageWarning;
