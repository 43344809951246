import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: "#fff",

    display: "none !important",
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
    },
  },
  formTitle: {
    fontSize: 24,
    color: theme.palette.secondary.main,
  },
  fab: {
    backgroundColor: "#EB001B",
  },
  fabIcon: {
    width: 30,
    height: 30,
  },
  filterContainer: {
    // maxHeight: 223,
    opacity: 1,
    transition: "all .8s ease-out",
    textAlign: "center",
    overflow: "hidden",
  },
  hidden: {
    maxHeight: 0,
    opacity: 0,
  },
  filterLabel: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  filterIcon: {
    width: 16,
    height: 16,
  },
  icon: {
    color: theme.palette.secondary.main,
    width: 24,
    height: 24,
  },
  filterButtonContainer: {
    width: 0,
    overflowX: "hidden",
    transition: "width .5s ease-in .8s",
    flexWrap: "nowrap",
  },
  grow: {
    width: 80,
    marginLeft: 15,
  },
  shrink: {
    minWidth: "0px !important",
    flex: 1,
  },
  responsiveBox: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      "& .MuiGrid-root + div": {
        marginTop: 20,
        width: "100% !important",
        height: "60vh !important",
      },
    },
  },
  helperText: {
    display: "none",
    maxWidth: 233,
    fontSize: 12,
    color: theme.palette.primary.main,
    alignSelf: "flex-start",
    marginLeft: 8,
    marginTop: 8,
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  filterInputContainer: {
    padding: "10px 10px 0px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      boxShadow:
        "0px -1px 13px rgba(0, 0, 0, 0.2), 0px 0px 11px rgba(0, 0, 0, 0.12), 0px -2px 11px rgba(0, 0, 0, 0.14)"
    },
  },
  mapContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 48px - 56px - 150px)",
    },
    height: "calc(100vh - 114px)",
    position: "relative",
  },
  floatingContainer: {
    position: "absolute",
    top: 50,
    left: 50,
    maxWidth: 382,
    overflow: "hidden",
    "& .react-swipeable-view-container > div": {
      overflow: "hidden !important",
    },
  },
  mobileFloatingContainer: {
    position: "absolute",
    top: 50,
    left: "50%",
    transform: "translateX(-50%)",
    width: "90%",
    overflow: "hidden",
    minWidth: 292,
  },
  mapFilterButtonsGroup: {
    padding: "1rem 0",
    "& .MuiGrid-item": {
      textAlign: "center",
      flex: "25%",
    },
  },
  mapFiltertitle: {
    color: "#979797",
    fontSize: 16,
    letterSpacing: 0.15,
  },
  mapFilterButton: {
    backgroundColor: theme.palette.primary.dark,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  mapFilterButtonLabel: {
    fontSize: 10,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    color: "#979797",
    display: "block",
    marginTop: 5,
  },
  hideOnDesktop: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  filterContainerHeader: {
    backgroundColor: theme.palette.secondary.light,
    padding: "1rem",
    paddingTop: "3rem",
    width: 350,
  },
  transparentContainer: {
    opacity: 0.5,
    transition: "opacity .2s ease-in-out",
    "&:hover": {
      opacity: 1,
    },
  },
  panel2: {
    background: "white",
    justifyContent: "center",
    padding: "2rem 0 6rem",
    textAlign: "center",
  },
  titleDontFavorite: {
    fontSize: 48,
    marginTop: 10,
    color: theme.palette.secondary.main,
  },
  subtitleDontFavorite: {
    fontSize: 24,
    marginTop: 10,
    color: "#4B4B4B",
  },
  searchBtn: {
    padding: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  available: {
    textAlign: "center",
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    marginTop: 10,
    padding: 10,
  },
}));
