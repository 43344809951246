import { 
  getEventsByUserIdAPI, 
  getNewNotificationAPI, 
  getNotificationsByUserIdAPI, 
  readNotificationAPI, 
  updateEventsAPI 
} from "../sevices/userEventsService";
import jwt_decode from "jwt-decode";
import { SET_EVENT_LISTENERS } from "./types";

export const getEventsByUserId = (token, modules) => async (dispatch) => {
  const decoded = jwt_decode(token);
  const user = {
    id: decoded.user._id,
    email: decoded.user.email,
    modules
  };
  await dispatch(getEventsByUserIdAPI(token, user));
};

export const setEventListeners = (events) => async (dispatch) => {
  dispatch({ type: SET_EVENT_LISTENERS, payload: events });
};

export const updateUserEvents = (token, userEvents) => async (dispatch) => {
  const decoded = jwt_decode(token);
  const updatedUser = { userId: decoded.user._id, events: userEvents };
  await dispatch(updateEventsAPI(token, updatedUser, dispatch));
}

export const getNotificationsByUserId = (token) => async (dispatch) => {
  const decoded = jwt_decode(token);
  await dispatch(getNotificationsByUserIdAPI(token, decoded.user._id));
};

export const getNewNotification = (token) => async (dispatch) => {
  const decoded = jwt_decode(token);
  await dispatch(getNewNotificationAPI(token, decoded.user._id, dispatch));
};

export const readNotification = (token, notificationId) => async (dispatch) => {
  await dispatch(readNotificationAPI(token, notificationId));
};