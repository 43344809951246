import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTheme, withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import {
  Breadcrumbs,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  TextField,
  IconButton,
  Drawer,
  Hidden,
  Paper,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "@reach/router";
import { Search, ArrowBackIos } from "@material-ui/icons";
import './paper-styles.css'

import {
  getMedicalNetwork,
  selectMedicalService,
  clearMedicalNetwork,
  getMedicalNetworkServices,
  getMedicalNetworkTypes,
  searchMedicalNetwork,
  blockFilter,
  selectSupplierTypeNetwork,
  selectServiceTypeNetwork,
} from "../../actions/medicalNetworkActions";
import { FilterIcon } from "../../assets/icons";
import MedicalNetworkCardContainer from "../../components/medicalNetworkCardContainer";
import MedicalNetworkOptions from "../../components/medicalNetworkCardOptions";
import MedicalNetworkFilter from "../../components/medicalNetworkFilter";
import searchImage from "../../assets/images/woman_searching.svg";
import MapContainer from "../../components/mapContainer";
import ChipList from "../../components/chipList";
import "../../styles/animations.css";
import { MEDICAL_NETWORK_QUERY } from "../../utils/urls";
import { useStyles } from "./style";

const DesktopStyledTabs = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const DesktopStyledTab = withStyles((theme) => ({
  root: {
    color: "#000000 !important",
    textTransform: "capitalize",
    fontSize: 18,
    textAlign: "center",
    "&:focus": {
      color: "#FFFFFF !important",
      backgroundColor: theme.palette.primary.main,
    },
  },
  selected: {
    color: "#FFFFFF !important",
    backgroundColor: theme.palette.primary.main,
  },
}))((props) => <Tab {...props} />);

const MobileStyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #D4D4D4",
    "& .MuiTabs-scroller": {
      display: "block",
    },
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const MobileStyledTab = withStyles({
  root: {
    color: "#333 !important",
    textTransform: "capitalize",
    fontSize: 14,
    textAlign: "center",
    "&:focus": {
      color: "#666 !important",
    },
  },
})((props) => <Tab disableRipple {...props} />);

const FilterInput = withStyles({
  root: {
    minWidth: 265,
    transition: "min-width .5s ease-in .8s",
    "& label": {
      fontSize: 14,
    },
    "& label:not(.Mui-focused):not(.MuiInputLabel-shrink)": {
      top: -10,
    },
    "& label.Mui-focused": {
      opacity: 0,
    },
  },
})(TextField);

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ position: "relative", overflowX: "hidden" }}
    >
      {value === index && (
        <Box p={3} className={classes.responsiveBox}>
          {children}
        </Box>
      )}
    </div>
  );
}

function DesktopTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ position: "relative", overflow: "hidden" }}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const MedicalNetworkMain = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searching, ...medicalNetworks } = useSelector(
    (selector) => selector.medicalNetwork
  );
  const medicalNetworkServices = useSelector(
    (selector) => selector.medicalNetworkServices
  );
  const medicalNetworkTypes = useSelector(
    (selector) => selector.medicalNetworkTypes
  );
  const token = useSelector((state) => state.auth.token);
  const config = useSelector((state) => state.userConfig.data);
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [page, setPage] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [firstLocaction, setFirstLocaction] = useState(null);
  const [chips, setChips] = useState([]);
  const tsupplier =
    medicalNetworkTypes &&
    medicalNetworkTypes.data &&
    medicalNetworkTypes.data.length &&
    medicalNetworkTypes?.data?.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [item.name]: {
            value: item._id,
            active: false,
          },
        }),
      {}
    );

  const [filtersList, setFiltersList] = useState({
    nearest: { label: "Mas cercanos", show: false },
    favorite: { label: "Favoritos", show: false },
    specialities: [],
    tsupplier,
    location: {
      type: "location",
      value: "",
      search: "",
    },
  });

  const handleTabChange = (e, newIndex) => {
    setIndex(newIndex);
  };

  const handleCleanFilter = (filter) => {
    if (filter && filter.type && filter.type !== "location") {
      setFiltersList({
        ...filtersList,
        [filter.type]: filter.value,
      });
      return;
    }

    setChips([]);
    setFiltersList({
      ...filtersList,
      specialities: [],
      types: [],
      tsupplier,
      location: {
        type: "location",
        value: "",
        search: "",
      },
    });
  };

  const handleChangeIndex = (i) => {
    setIndex(i);
  };

  const handleSupplier = (tag, toggle) => {
    setFiltersList({
      ...filtersList,
      tsupplier: {
        ...filtersList.tsupplier,
        [tag]: {
          ...filtersList.tsupplier[tag],
          active: toggle,
        },
      },
    });
  };

  const handleSearchChange = async (isAboveMap = false) => {
    setSearch(true);
    dispatch(clearMedicalNetwork());
    const pageNumber = isAboveMap ? 0 : page;
    let data = await dispatch(
      getMedicalNetwork(
        token,
        pageNumber,
        searching,
        filtersList,
        MEDICAL_NETWORK_QUERY === "sise",
        medicalNetworkTypes.supplier.length
          ? [medicalNetworkTypes.supplier]
          : [],
        medicalNetworkTypes.service
      )
    );
    if (data.value.response.length > 0) {
      setFirstLocaction(data.value.response[0]?.adress.location);
    }
    dispatch(blockFilter());
    setPage(pageNumber + 1);
    setDisabled(false);
  };

  const executeInitMedicalNetworkServices = async () => {
    if (!medicalNetworkServices.data.length) {
      await dispatch(
        getMedicalNetworkServices(token, MEDICAL_NETWORK_QUERY === "sise")
      );
    }
  };

  useEffect(() => {
    executeInitMedicalNetworkServices();
    dispatch(getMedicalNetworkTypes(token, MEDICAL_NETWORK_QUERY === "sise"));
    if (!medicalNetworks.loading && !medicalNetworks?.data?.length) {
      handleSearchChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOptionsToggle = (open) => {
    setOptionsOpen(open);
  };

  const fetchMoreResults = () =>
    dispatch(
      getMedicalNetwork(
        token,
        page,
        searching,
        filtersList,
        MEDICAL_NETWORK_QUERY === "sise",
        medicalNetworkTypes.supplier.length
          ? [medicalNetworkTypes.supplier]
          : [],
        medicalNetworkTypes.service
      )
    );

  const handleSelect = (service) => {
    dispatch(selectMedicalService(service));
  };

  const handleToggleAdvancedOptions = (element, open) => {
    setPage(0);
    if (element) {
      setAdvancedFilter({
        element,
        open,
      });
    } else {
      setAdvancedFilter(null);
    }
  };

  const handleAddFilter = (filter) => {
    if (filter.value) {
      const _filtersList = filtersList;
      switch (filter.type) {
        case "specialities":
          if (!filter.value) {
            setFiltersList({
              ..._filtersList,
              specialities: [],
            });
          }
          if (filter.value) {
            setFiltersList({
              ..._filtersList,
              specialities: [filter.value],
            });
          }
          break;
        case "types":
          if (!filter.value) {
            setFiltersList({
              ..._filtersList,
              types: [],
            });
          }
          if (filter.value) {
            setFiltersList({
              ..._filtersList,
              types: [filter.value],
            });
          }
          break;
        case "location":
          setFiltersList({
            ..._filtersList,
            location: { ...filter },
          });
          break;
        default:
          setFiltersList({
            ..._filtersList,
            [filter.type]: {
              ..._filtersList[filter.type],
              show: !_filtersList[filter.type]?.show,
            },
          });
          break;
      }
    }
  };

  const getIndexByFilterState = (filter) => {
    if (filter) return 1;

    return 0;
  };

  /*
  useEffect(() => {
    const locChip = filtersList.location.value;
    let newLocChip = "";
    switch (locChip) {
      case "street":
        newLocChip = "Calle";
        break;
      case "city":
        newLocChip = "Ciudad";
        break;
      case "state":
        newLocChip = "Estado";
        break;
    }

    newLocChip = `${newLocChip}=${filtersList.location.search}`;

    setChips(
      Object.keys(filtersList)
        .filter((filter) => filtersList[filter]?.show)
        .map((filter) => filtersList[filter].label)
        .concat(filtersList.specialities.concat(filtersList.types))
        .concat(newLocChip !== "=" ? newLocChip : "")
        .filter((f) => f)
    );
  }, [filtersList, filtersList.location]);*/

  const handleDelete = (chip) => {
    if (chip.type === "types") {
      dispatch(selectSupplierTypeNetwork(""));
    }
    if (chip.type === "specialities") {
      dispatch(selectServiceTypeNetwork(""));
    }
    if (chip.type === "location") {
      handleCleanFilter({
        type: "location",
        value: {
          type: "location",
          value: "",
          search: "",
        },
      });
    } else {
      handleAddFilter(chip);
    }

    setChips(chips.filter((itemChip) => itemChip.type !== chip.type));
  };

  return (
    <Grid container direction="column">
      <div style={{ marginBottom: 16, padding: 16 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link to="/dashboard">Home</Link>
          <Typography color="textPrimary">Red Médica</Typography>
        </Breadcrumbs>
      </div>

      {/* desktop */}
      <Hidden smDown>
        <div>
          <DesktopStyledTabs
            value={0} // TODO: colocar index al value cuando se agregue pestaña favoritos
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs"
          >
            <DesktopStyledTab label="Busqueda" {...a11yProps(0)} />
            {/* TODO: OCULTO PARA PRIMERA VERSION */}
            {/* <DesktopStyledTab label="Favoritos" {...a11yProps(1)} /> */}
          </DesktopStyledTabs>

          {/* TODO: colocar index al value cuando se agregue pestaña favoritos */}
          <TabPanel value={0} index={0} dir={theme.direction} classes={classes}>
            <div className={classes.mapContainer}>
              <MapContainer
                data={medicalNetworks.data && medicalNetworks.data.slice()}
                selected={medicalNetworks.selected}
                handleSelect={handleSelect}
                handleOptionsToggle={handleOptionsToggle}
                desktop
                search={search}
              />
              <div
                className={clsx(
                  classes.floatingContainer,
                  search && classes.transparentContainer
                )}
              >
                <Paper elevation={0}>
                  <SwipeableViews
                    disabled
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={getIndexByFilterState(advancedFilter)}
                  >
                    <DesktopTabPanel
                      value={getIndexByFilterState(advancedFilter)}
                      index={0}
                      dir={theme.direction}
                    >
                      <>
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="center"
                          alignItems="center"
                          className={clsx(classes.filterInputContainer)}
                        >
                          <FilterInput
                            className={clsx(search && classes.shrink)}
                            placeholder="Busca profesionales, centros de salud, etc..."
                            value={searching}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                handleSearchChange(true);
                              }
                            }}
                            onChange={(e) => {
                              const val = e.target.value;
                              dispatch(searchMedicalNetwork(val));
                              setSearch(false);
                              dispatch(blockFilter(true));
                            }}
                            onBlur={() => {
                              setPage(0);
                              setDisabled(true);
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            size="small"
                            disabled={!disabled}
                            onClick={() => {
                              handleSearchChange();
                            }}
                            className={classes.searchBtn}
                          >
                            <Search className={classes.icon} />
                          </IconButton>

                          <IconButton
                            color="inherit"
                            onClick={(evt) => {
                              handleToggleAdvancedOptions(
                                evt.currentTarget,
                                true
                              );
                              setFiltersList((prevState) => ({
                                ...prevState,
                                tsupplier: tsupplier,
                              }));
                            }}
                          >
                            <FilterIcon
                              className={classes.icon}
                              color={
                                config.theme && config.theme.secondary.main
                              }
                            />
                          </IconButton>
                        </Grid>
                        {search && (
                          <ChipList
                            chips={chips}
                            onToggle={handleToggleAdvancedOptions}
                          />
                        )}
                        <div
                          style={
                            !search
                              ? { height: 0 }
                              : {
                                  minHeight: 80,
                                  height: !medicalNetworks?.data?.length
                                    ? "auto"
                                    : 500,
                                }
                          }
                        >
                          <>
                            {!medicalNetworks.loading &&
                            medicalNetworks?.data?.length ? (
                              <small
                                className={classes.available}
                              >{`Se encontraron ${medicalNetworks.data.length} puntos disponibles`}</small>
                            ) : null}
                            {!medicalNetworks.loading &&
                            !medicalNetworks?.data?.length ? (
                              <small
                                className={classes.available}
                              >{`No se encontraron  puntos disponibles`}</small>
                            ) : null}

                            <MedicalNetworkCardContainer
                              search={search}
                              data={medicalNetworks.data}
                              count={medicalNetworks.count}
                              loading={medicalNetworks.loading}
                              handleRequest={fetchMoreResults}
                              handleOptionsToggle={handleOptionsToggle}
                              handleSelect={handleSelect}
                            />

                          </>
                        </div>
                      </>
                    </DesktopTabPanel>

                    <DesktopTabPanel
                      value={getIndexByFilterState(advancedFilter)}
                      index={1}
                      dir={theme.direction}
                      style={{ overflowX: "hidden" }}
                    >
                      <div className={classes.filterContainerHeader}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <IconButton
                              color="inherit"
                              onClick={(e) => {
                                handleToggleAdvancedOptions(null, false);
                              }}
                            >
                              <ArrowBackIos style={{ color: "#fff" }} />
                            </IconButton>
                          </Grid>
                          <Grid
                            container
                            item
                            alignItems="center"
                            className={clsx(
                              classes.filterButtonContainer,
                              advancedFilter && classes.grow
                            )}
                          >
                            <Typography
                              style={{ color: "#fff" }}
                              variant="body1"
                              component="span"
                              className={classes.filterLabel}
                            >
                              Filtros
                            </Typography>
                            <FilterIcon
                              className={classes.filterIcon}
                              color="#fff"
                              fill={
                                config.theme && config.theme.secondary.variant1
                              }
                              style={{ marginLeft: 20 }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      {advancedFilter && (
                        <ChipList
                          chips={chips}
                          desktop
                          handleDelete={handleDelete}
                          deleteable
                          disabled
                        />
                      )}
                      <MedicalNetworkFilter
                        {...advancedFilter}
                        offset={100}
                        setChips={setChips}
                        handleClose={handleToggleAdvancedOptions}
                        handleAddFilter={handleAddFilter}
                        filters={filtersList}
                        handleReload={handleSearchChange}
                        onFilterClean={handleCleanFilter}
                        desktop
                        handleSupplier={handleSupplier}
                      />
                    </DesktopTabPanel>
                  </SwipeableViews>
                </Paper>

                {/* TODO: OCULTO PARA PRIMERA VERSION */}
                {/* <Paper elevation={7} style={{ marginTop: '2rem' }} className={clsx(search && classes.hidden)}>
                  <Box p={1}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Typography component="span" variant="body1" className={classes.mapFiltertitle}>
                        Cerca tuyo
                      </Typography>
                      <Room className={classes.icon} />
                    </Grid>
                    <Grid container justifyContent="space-around" alignItems="center" className={classes.mapFilterButtonsGroup}>
                      <Grid item>
                        <IconButton className={classes.mapFilterButton}>
                          <MedicalCenterIcon />
                        </IconButton>
                        <span className={classes.mapFilterButtonLabel}>Farmacias</span>
                      </Grid>
                      <Grid item>
                        <IconButton className={classes.mapFilterButton}>
                          <MedicalCenterIcon />
                        </IconButton>
                        <span className={classes.mapFilterButtonLabel}>Hospitales</span>
                      </Grid>
                      <Grid item>
                        <IconButton className={classes.mapFilterButton}>
                          <MedicalCenterIcon />
                        </IconButton>
                        <span className={classes.mapFilterButtonLabel}>Clinicas</span>
                      </Grid>
                      <Grid item>
                        <IconButton className={classes.mapFilterButton}>
                          <ProfessionalDoctorIcon />
                        </IconButton>
                        <span className={classes.mapFilterButtonLabel}>Consultorios</span>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper> */}
              </div>
            </div>
          </TabPanel>

          {/* TODO: CREAR COMPONENTE PARA CUANDO NO HAY FAVORITOS y colocar componente en tab mobile */}
          {/* <TabPanel value={index} index={1} dir={theme.direction} classes={classes}>
            <div className={classes.panel2}>
              <img loading="lazy" alt="empty favorite" src={emptyFavorite} />
              <Typography className={classes.titleDontFavorite} gutterBottom color="textPrimary">Todavía no has guardado nada</Typography>
              <Typography className={classes.subtitleDontFavorite} gutterBottom color="textPrimary">Para empezar a guardar favoritos <br />realiza una búsqueda</Typography>
            </div>
          </TabPanel> */}
        </div>
      </Hidden>

      {/* mobile */}
      <Hidden mdUp>
        <div>
          <MobileStyledTabs
            value={index}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs"
            className={classes.tabs}
          >
            <MobileStyledTab label="Listado" {...a11yProps(0)} />
            <MobileStyledTab label="Mapa" {...a11yProps(1)} />
            {/* TODO: OCULTO PARA PRIMERA VERSION */}
            {/* <MobileStyledTab label="Favoritos" {...a11yProps(2)} /> */}
          </MobileStyledTabs>

          <TabPanel
            value={index}
            index={0}
            dir={theme.direction}
            classes={classes}
            style={{ position: "relative" }}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid
                container
                item
                direction="column"
                xs={12}
                md={4}
                justifyContent="center"
                alignItems="center"
              >
                <div
                  className={clsx(
                    classes.filterContainer,
                    search && classes.hidden,
                    search && !medicalNetworks.loading && classes.hide
                  )}
                >
                  <img src={searchImage} alt="Search" />
                  <Typography
                    component="h5"
                    variant="h5"
                    color="inherit"
                    noWrap
                    className={classes.formTitle}
                  >
                    ¿Qué estás buscando?
                  </Typography>
                </div>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  className={clsx(classes.filterInputContainer)}
                >
                  <FilterInput
                    className={clsx(search && classes.shrink)}
                    label="Busca profesionales, centros de salud,, hospitales..."
                    value={searching}
                    onChange={(e) => {
                      const val = e.target.value;
                      dispatch(searchMedicalNetwork(val));
                    }}
                    onBlur={() => {
                      setPage(0);
                      setDisabled(true);
                    }}
                  />
                  <Grid
                    container
                    item
                    alignItems="center"
                    className={clsx(
                      classes.filterButtonContainer,
                      search && classes.grow
                    )}
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      className={classes.filterLabel}
                    >
                      Filtros
                    </Typography>
                    <IconButton
                      color="inherit"
                      onClick={(evt) => {
                        handleToggleAdvancedOptions(evt.currentTarget, true);
                        setFiltersList((prevState) => ({
                          ...prevState,
                          tsupplier: tsupplier,
                        }));
                      }}
                    >
                      <FilterIcon className={classes.filterIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
                {advancedFilter && (
                  <ChipList
                    chips={chips}
                    handleDelete={handleDelete}
                    deleteable
                    disabled
                  />
                )}
                {!advancedFilter && (
                  <Typography
                    variant="body1"
                    component="span"
                    className={clsx(
                      classes.helperText,
                      search &&
                        medicalNetworks.data &&
                        medicalNetworks.data.length > 30 &&
                        classes.show
                    )}
                  >
                    Parece que tu búsqueda es muy amplia, ¿por qué no pruebas
                    con un filtro?
                  </Typography>
                )}
                {!advancedFilter && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      // disabled={!disabled}
                      onClick={() => handleSearchChange(true)}
                      style={{ margin: "1.5rem 0" }}
                    >
                      Buscar
                    </Button>
                    <Grid container justifyContent="center">
                      {medicalNetworks && medicalNetworks.loading && (
                        <CircularProgress />
                      )}
                    </Grid>
                  </>
                )}
                {!medicalNetworks.loading && medicalNetworks?.data?.length ? (
                  <small
                    className={classes.available}
                  >{`Se encontraron ${medicalNetworks.data.length} puntos disponibles`}</small>
                ) : null}
                {!medicalNetworks.loading && !medicalNetworks?.data?.length ? (
                  <small
                    className={classes.available}
                  >{`No se encontraron  puntos disponibles`}</small>
                ) : null}
              </Grid>
              <MedicalNetworkCardContainer
                search={search}
                data={medicalNetworks.data}
                count={medicalNetworks.count}
                handleRequest={fetchMoreResults}
                loading={medicalNetworks.loading}
                handleOptionsToggle={handleOptionsToggle}
                handleSelect={handleSelect}
                isMobile
                onChangeIndex={handleChangeIndex}
              />
            </Grid>
          </TabPanel>
          <TabPanel
            value={index}
            index={1}
            dir={theme.direction}
            classes={classes}
          >
            <div className={classes.mapContainer}>
              <MapContainer
                data={medicalNetworks.data && medicalNetworks.data.slice()}
                selected={medicalNetworks.selected}
                handleSelect={handleSelect}
                handleOptionsToggle={handleOptionsToggle}
                firstLocaction={firstLocaction}
                search={search}
              />
              <div
                className={clsx(
                  classes.mobileFloatingContainer,
                  search && classes.transparentContainer
                )}
              >
                <Paper elevation={7}>
                  <Box p={1} style={{ paddingRight: 0 }}>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      className={clsx(classes.filterInputContainer)}
                      style={{ flexWrap: "nowrap" }}
                    >
                      <FilterInput
                        className={clsx(search && classes.shrink)}
                        placeholder="Busca profesionales, centros de salud, etc..."
                        value={searching}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            handleSearchChange(true);
                          }
                        }}
                        onChange={(e) =>
                          dispatch(searchMedicalNetwork(e.target.value))
                        }
                        style={{ flex: 1, minWidth: 180 }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onBlur={() => {
                          setPage(0);
                          setDisabled(true);
                        }}
                      />
                      <div
                        style={{
                          borderRight: "1px solid #DEDEDE",
                          padding: 10,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Search
                          className={classes.icon}
                          onClick={() => {
                            handleSearchChange(true);
                          }}
                        />
                      </div>
                      <IconButton
                        color="inherit"
                        onClick={(evt) => {
                          handleToggleAdvancedOptions(evt.currentTarget, true);
                          setFiltersList((prevState) => ({
                            ...prevState,
                            tsupplier: tsupplier,
                          }));
                        }}
                      >
                        <FilterIcon
                          className={classes.icon}
                          color={config.theme && config.theme.secondary.main}
                        />
                      </IconButton>
                    </Grid>
                  </Box>
                </Paper>
              </div>
            </div>
          </TabPanel>

          {/* comentado en primera version */}
          {/* <TabPanel value={index} index={2} dir={theme.direction} classes={classes}>
                Favoritos
              </TabPanel> */}
          {/* <StyledFab aria-label="Emergency" className={clsx((search || index !== 0)  && classes.hide)}>
              <ErIcon className={classes.fabIcon} />
            </StyledFab> */}
          <MedicalNetworkFilter
            {...advancedFilter}
            offset={100}
            handleClose={handleToggleAdvancedOptions}
            handleAddFilter={handleAddFilter}
            handleReload={handleSearchChange}
            filters={filtersList}
            setChips={setChips}
            onFilterClean={handleCleanFilter}
            handleSupplier={handleSupplier}
          />
          {search && (
            <Drawer
              variant="persistent"
              anchor="bottom"
              open={optionsOpen}
              onClose={() => {
                handleOptionsToggle(false);
              }}
              className={classes.drawer}
              ModalProps={{
                BackdropProps: {
                  invisible: true,
                }
              }}
            >
              <MedicalNetworkOptions
                handleClose={handleOptionsToggle}
                selected={medicalNetworks.selected}
              />
            </Drawer>
          )}
        </div>
      </Hidden>
    </Grid>
  );
};

export default MedicalNetworkMain;
