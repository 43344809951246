import {
  GET_MEDICAL_NETWORK_SERVICES,
  GET_MEDICAL_NETWORK_SERVICES_FULFILLED,
  GET_MEDICAL_NETWORK_SERVICES_REJECTED,
  GET_MEDICAL_NETWORK_SERVICES_PENDING,
} from "../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MEDICAL_NETWORK_SERVICES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_MEDICAL_NETWORK_SERVICES_FULFILLED:
      let services = [];
      services = action.payload;
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.length
      ) {
        services = action.payload.response;
      }

      return {
        ...state,
        data: services,
        loading: false,
        error: false,
      };
    case GET_MEDICAL_NETWORK_SERVICES_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case GET_MEDICAL_NETWORK_SERVICES:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
}
