import React from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  InputLabel,
  Grid,
  Box
} from "@material-ui/core";
import NumberFormat from 'react-number-format';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Visibility, VisibilityOff, CheckCircleOutline } from "@material-ui/icons";

export const renderTextField = ({
  input,
  label,
  disabled,
  custom: { tabIndex, placeholder, variant, size },
  meta: { touched, error }
}) => {
  return (
    <Grid style={{ position: 'relative' }}>
      <TextField
        className="formInput"
        label={label}
        error={!disabled && touched && !!error}
        helperText={!disabled && touched && !!error ? error : false}
        placeholder={placeholder}
        inputProps={{ tabIndex }}
        fullWidth
        variant={variant ? variant : "standard"}
        size={size ? size : "small"}
        disabled={disabled}
        {...input}
      />
        {
            disabled ? (
                null
            ) : error == undefined && !disabled ? (
                    <Box style={{ position: 'absolute', right: 0, bottom: 2 }}>
                        <CheckCircleOutline style={{ color: '#23D9B7' }}/>
                    </Box>
                ) : null
        }
    </Grid>
  );
};

export const renderPhoneField = ({
    input,
    label,
    disabled,
    value,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error }
  }) => {
    return (
      <Grid style={{ position: 'relative' }}>
            <NumberFormat
                {...input}
                label={label}
                customInput={TextField} 
                error={!disabled && touched && !!error}
                helperText={!disabled && touched && !!error ? error : false}
                disabled={disabled}
                format="## ### ### ## ##"
                value={value}
                style={{
                    width: '100%'
                }}
            />
            {
                disabled ? (
                    null
                ) : error == undefined && !disabled ? (
                        <Box style={{ position: 'absolute', right: 0, bottom: 2 }}>
                            <CheckCircleOutline style={{ color: '#23D9B7' }}/>
                        </Box>
                    ) : null
            }
      </Grid>
    );
  };

export const RenderPasswordField = ({
  input,
  label,
  handleChange,
  custom: { tabIndex, placeholder, variant, size },
  meta: { touched, error },
}) => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChangeInside = (prop) => (event) => {
    handleChange();
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl>
      <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
      <Input
        {...input}
        id="standard-adornment-password"
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChangeInside("password")}
        error={touched && !!error}
        helperText={touched && !!error ? error : false}
        placeholder={placeholder}
        inputProps={{ tabIndex }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export const renderNumberField = ({
  input,
  label,
  disabled = false,
  meta: { touched, error },
}) => {
  return (
    <TextField
      type="number"
      className="formInput"
      label={label}
      disabled={disabled}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      fullWidth
      {...input}
    />
  );
};

export const renderSelectField = ({
  input,
  label,
  children,
  disabled = false,
  custom: { tabIndex, placeholder, variant, size },
  meta: { touched, error },
}) => {
  return (
    <TextField
      select
      className="formInput"
      label={label}
      disabled={disabled}
      children={children}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex }}
      variant={variant || "standard"}
      size={size || "small"}
      fullWidth
      {...input}
    />
  );
};

export const renderMultiSelectField = ({
  input,
  label,
  children,
  custom: { tabIndex, placeholder, variant, size },
  meta: { touched, error },
}) => {
  return (
    <TextField
      select
      className="formInput"
      label={label}
      children={children}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex }}
      variant={variant ? variant : "standard"}
      size={size ? size : "small"}
      fullWidth
      SelectProps={{
        multiple: true,
      }}
      {...input}
      value={input.value === "" ? [] : input.value}
    />
  );
};

export const renderMultiSelectCheckboxField = ({
  input,
  label,
  getName = null,
  children,
  custom: { tabIndex, placeholder = "Seleccione para agregar", variant, size },
  meta: { touched, error },
}) => {
  return (
    <TextField
      select
      className="formInput"
      label={label}
      children={children}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex }}
      variant={variant ? variant : "standard"}
      size={size ? size : "small"}
      fullWidth
      SelectProps={{
        multiple: true,
        renderValue: (selected) => {
          if (selected.length === 0) {
            return <em>{placeholder}</em>;
          }
          return getName ? getName(selected).join(", ") : selected.join(", ");
        },
      }}
      {...input}
      value={input.value === "" ? [] : input.value}
    />
  );
};

export const renderDateField = ({
  input: { onBlur, value, ...otherInputProps },
  label,
  custom: { tabIndex },
  meta: { touched, error },
  ...props
}) => {
  const handleChange = (date) => {
    Date.parse(date)
      ? otherInputProps.onChange(date)
      : otherInputProps.onChange(null);
  };

  return (
    <KeyboardDatePicker
      autoOk
      variant="inline"
      format="DD/MM/yyyy"
      className="formInput"
      label={label}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex }}
      fullWidth
      value={value ? new Date(value) : null}
      onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
      onChange={handleChange}
      {...otherInputProps}
      {...props}
    />
  );
};

export const renderDateTimeField = ({
  input,
  label,
  defaultValue = undefined,
  custom: { tabIndex },
  meta: { touched, error },
}) => {
  return (
    <TextField
      type="datetime-local"
      className="formInput"
      label={label}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex, value: defaultValue }}
      fullWidth
      {...input}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export const renderCheckbox = ({ input, label, disabled = false }) => (
  <FormControlLabel
    control={
      <Checkbox
        color="primary"
        disabled={disabled}
        checked={!!input.value}
        onChange={input.onChange}
      />
    }
    label={label}
  />
);

export const renderRadioGroup = ({
  input,
  custom: { options },
  meta: { touched, error },
  ...rest
}) => (
  <FormControl>
    <RadioGroup value={input.value} onChange={input.onChange} {...rest}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio color="primary" />}
          label={option.label}
        />
      ))}
    </RadioGroup>
    {touched && !!error && (
      <FormHelperText error={true}>{error}</FormHelperText>
    )}
  </FormControl>
);

export const RenderTextFieldDisabled = ({ label, value }) => (
  <TextField
    label={label}
    value={value || ""}
    className="formInput"
    margin="normal"
    disabled
    fullWidth
  />
);

export const RenderCheckboxDisabled = ({ label, value }) => (
  <FormControlLabel
    control={<Checkbox color="primary" checked={value} />}
    label={label}
    disabled
  />
);

export const RenderRadioGroupDisabled = ({ label, value, options }) => (
  <FormControl>
    <RadioGroup value={value}>
      {options.map((option) => (
        <FormControlLabel
          disabled
          key={option.value}
          value={option.value}
          control={<Radio color="primary" />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
);
