import {
    GET_USER_BY_ID_PENDING,
    GET_USER_BY_ID_FULFILLED,
    GET_USER_BY_ID_REJECTED
} from "../actions/types";

const initialState = {
    data: {},
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_BY_ID_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_USER_BY_ID_FULFILLED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        case GET_USER_BY_ID_REJECTED:
            return {
                ...state,
                data: {},
                loading: false,
                error: true,
            };
        default:
            return state;
    }
}