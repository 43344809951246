import {
  GET_MASSIVE_COLLECTIVE_REQUEST_PENDING,
  GET_MASSIVE_COLLECTIVE_REQUEST_FULFILLED,
  GET_MASSIVE_COLLECTIVE_REQUEST_REJECTED,
  GET_COLLECTIVE_REQUEST_IN_PENDING,
  GET_COLLECTIVE_REQUEST_IN_FULFILLED,
  GET_COLLECTIVE_REQUEST_IN_REJECTED,
  ADD_MASSIVE_COLLECTIVE_REQUEST_PENDING,
  ADD_MASSIVE_COLLECTIVE_REQUEST_FULFILLED,
  ADD_MASSIVE_COLLECTIVE_REQUEST_REJECTED,
  UPDATE_MASSIVE_COLLECTIVE_REQUEST_PENDING,
  UPDATE_MASSIVE_COLLECTIVE_REQUEST_FULFILLED,
  UPDATE_MASSIVE_COLLECTIVE_REQUEST_REJECTED,
  SET_UNAVAILABLE_COLLECTIVE_REQUEST_PENDING,
  SET_UNAVAILABLE_COLLECTIVE_REQUEST_FULFILLED,
  SET_UNAVAILABLE_COLLECTIVE_REQUEST_REJECTED,
  UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_PENDING,
  UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_FULFILLED,
  UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_REJECTED,
  DELETE_MASSIVE_COLLECTIVE_REQUEST_PENDING,
  DELETE_MASSIVE_COLLECTIVE_REQUEST_FULFILLED,
  DELETE_MASSIVE_COLLECTIVE_REQUEST_REJECTED,
  FILTER_MASSIVE_COLLECTIVE_REQUEST,
  RESET_MASSIVE_COLLECTIVE_REQUEST,
} from "../actions/types";

const initialState = {
  data: [],
  filteredData: [],
  loading: false,
  error: false,
  selected: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MASSIVE_COLLECTIVE_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_MASSIVE_COLLECTIVE_REQUEST_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_MASSIVE_COLLECTIVE_REQUEST_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };

    case GET_COLLECTIVE_REQUEST_IN_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_COLLECTIVE_REQUEST_IN_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_COLLECTIVE_REQUEST_IN_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };

    case ADD_MASSIVE_COLLECTIVE_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_MASSIVE_COLLECTIVE_REQUEST_FULFILLED:
      return {
        ...state,
        data: [...state.data, action.payload],
        filteredData: [...state.data, action.payload],
        loading: false,
        error: false,
      };
    case ADD_MASSIVE_COLLECTIVE_REQUEST_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_PENDING:
    case SET_UNAVAILABLE_COLLECTIVE_REQUEST_PENDING:
    case UPDATE_MASSIVE_COLLECTIVE_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_FULFILLED:
    case SET_UNAVAILABLE_COLLECTIVE_REQUEST_FULFILLED:
    case UPDATE_MASSIVE_COLLECTIVE_REQUEST_FULFILLED:
      const newData = state.data.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }

        return item;
      });

      return {
        ...state,
        data: newData,
        filteredData: newData,
        loading: false,
        error: false,
      };
    case UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_REJECTED:
    case SET_UNAVAILABLE_COLLECTIVE_REQUEST_REJECTED:
    case UPDATE_MASSIVE_COLLECTIVE_REQUEST_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case DELETE_MASSIVE_COLLECTIVE_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_MASSIVE_COLLECTIVE_REQUEST_FULFILLED:
      const updatedData = state.data.filter((d) => {
        if (d._id !== action.payload.id) {
          return true;
        }

        return !action.payload.status;
      });
      return {
        ...state,
        data: updatedData,
        filteredData: updatedData,
        loading: false,
        error: false,
      };
    case DELETE_MASSIVE_COLLECTIVE_REQUEST_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    /*case FILTER_MASSIVE_COLLECTIVE_REQUEST:
      const { filters, rules } = action.payload;
      const filtered = state.data.filter((d) => {
        const conditions = Object.keys(filters).map((filter) =>
          getFilterCondition(
            filters[filter],
            d[rules[filter].filterKey],
            rules[filter].comparationType
          )
        );

        return conditions.every((c) => c);
      });

      return {
        ...state,
        filteredData: filtered,
      };*/
    case RESET_MASSIVE_COLLECTIVE_REQUEST:
      return {
        ...state,
        filteredData: state.data,
      };
    default:
      return state;
  }
}
