import { createAsyncAction } from 'redux-promise-middleware-actions';

import { validateJSONResponse, exceptionCodeResponse } from '../utils/urls';
import { GET_FAQS } from '../actions/types';
import { quotationUrlApi } from '../utils/urls'

export const getFaqsApi = createAsyncAction(
    GET_FAQS,
    async (token) => {
        const res = await fetch(`${quotationUrlApi}faq`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                return validateJSONResponse(res)
            })
            .catch((err) => {
                throw exceptionCodeResponse(err);
            });
        return res;
    }
);
