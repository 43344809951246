import { createAsyncAction } from 'redux-promise-middleware-actions';
import { quotationUrlApi } from '../utils/urls';

export const getCoverAPI = createAsyncAction('GET_COVER', async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const res = await fetch(`${quotationUrlApi}coverage`, { headers })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
});

export const getAsistanceAPI = createAsyncAction(
    'GET_ASISTANCE',
    async (token) => {
        const headers = { Authorization: `Bearer ${token}` };
        const res = await fetch(`${quotationUrlApi}assistance`, { headers })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getTaxAPI = createAsyncAction('GET_TAX', async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const res = await fetch(`${quotationUrlApi}tax`, { headers })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
});

export const getLineBussinessAPI = createAsyncAction(
    'GET_LINE_BUSSINESS',
    async (token) => {
        const headers = { Authorization: `Bearer ${token}` };
        const res = await fetch(`${quotationUrlApi}bussinessLine`, { headers })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getCurrencyAPI = createAsyncAction(
    'GET_CURRENCY',
    async (token) => {
        const headers = { Authorization: `Bearer ${token}` };
        const res = await fetch(`${quotationUrlApi}currency`, { headers })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getRisktypeAPI = createAsyncAction(
    'GET_RISK_TYPE',
    async (token) => {
        const headers = { Authorization: `Bearer ${token}` };
        const res = await fetch(`${quotationUrlApi}risktype`, { headers })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};
