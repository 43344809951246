import {
    GET_REQUEST_WORKFLOW_PENDING,
    GET_REQUEST_WORKFLOW_FULFILLED,
    GET_REQUEST_WORKFLOW_REJECTED,
    ADD_REQUEST_WORKFLOW_PENDING,
    ADD_REQUEST_WORKFLOW_FULFILLED,
    ADD_REQUEST_WORKFLOW_REJECTED,
    UPDATE_REQUEST_WORKFLOW_PENDING,
    UPDATE_REQUEST_WORKFLOW_FULFILLED,
    UPDATE_REQUEST_WORKFLOW_REJECTED,
    DELETE_REQUEST_WORKFLOW_PENDING,
    DELETE_REQUEST_WORKFLOW_FULFILLED,
    DELETE_REQUEST_WORKFLOW_REJECTED,
    SET_FILTER_REQUEST_WORKFLOW,
    RESET_FILTER_REQUEST_WORKFLOW,
    DELETE_FILTER_REQUEST_WORKFLOW,
    FILTER_REQUEST_WORKFLOW,
    RESET_REQUEST_WORKFLOW,
    LOCK_SAVE_BUTTON_REQUESTWF,
    INCREASE_ROW_COUNTER_IN_TABLE,
    DECREMENT_ROW_COUNTER_IN_TABLE,
    RESET_ROW_COUNTER_IN_TABLE,
    SET_FALSE_IS_ASSIGNED_TO_ME,
    GET_ASSIGNED_TO_ME_REQUESTS_PENDING,
    GET_ASSIGNED_TO_ME_REQUESTS_FULFILLED,
    GET_ASSIGNED_TO_ME_REQUESTS_REJECTED,
} from '../actions/types';
import moment from 'moment';

const initialState = {
    data: [],
    lockSaveButtonRequestWF: false,
    numberOfRowsInTable: 0,
    originalData: [],
    filteredData: [],
    filterRules: [],
    loading: false,
    error: false,
    selected: null,
    isAssignedToMe: false,
};

const compareRule = ({ propertyName, searchValue, originalValue }) => {
    if (!propertyName || !searchValue || !originalValue) {
        return false;
    }

    const isDate = propertyName === 'createdAt'; //todo: create a flag for dates

    if (isDate) {
        return moment(originalValue).isSame(searchValue, 'day');
    }

    const LC_originalValue = originalValue.toLowerCase();
    const LC_searchValue = searchValue.toLowerCase();

    //return value1 === value2;
    return LC_originalValue.includes(LC_searchValue);
};

const filterData = ({ data, rules }) => {
    if (!rules.length) {
        return data;
    }

    let filteredData = [];

    rules.map((rule) => {
        const newFilteredData = data.filter((item) =>
            compareRule({
                propertyName: rule.type,
                originalValue: item[rule.type],
                searchValue: rule.id,
            })
        );
        filteredData = [...newFilteredData, ...filteredData];
    });

    return filteredData;
};

const checkIsDuplicateRule = (newData, oldData) => {
    if (!newData || !oldData.length) {
        return false;
    }

    const existFilterRule = oldData.find(
        (item) => item.id === newData.id && item.type === newData.type
    );

    return existFilterRule;
};

const filterNewRules = (rules, newRule) => {
    if (!newRule) {
        return rules;
    }

    if (!rules.length) {
        return [newRule];
    }

    const typeTarget = newRule.type;
    const filteredRules = rules.filter((item) => item.type !== typeTarget);

    const mergedFilter = [...filteredRules, newRule];

    return mergedFilter;
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_REQUEST_WORKFLOW_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_REQUEST_WORKFLOW_FULFILLED:
            return {
                ...state,
                //data: action.payload,
                data: filterData({
                    data: action.payload,
                    rules: state.filterRules,
                }),
                filteredData: action.payload,
                originalData: action.payload,
                loading: false,
                error: false,
                isAssignedToMe: false,
            };
        case GET_REQUEST_WORKFLOW_REJECTED:
            return {
                ...state,
                data: [],
                filteredData: [],
                originalData: [],
                loading: false,
                error: true,
            };

        case GET_ASSIGNED_TO_ME_REQUESTS_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
                isAssignedToMe: true,
            };
        case GET_ASSIGNED_TO_ME_REQUESTS_FULFILLED:
            return {
                ...state,
                //data: action.payload,
                data: filterData({
                    data: action.payload,
                    rules: state.filterRules,
                }),
                filteredData: action.payload,
                originalData: action.payload,
                loading: false,
                error: false,
                isAssignedToMe: true,
            };
        case GET_ASSIGNED_TO_ME_REQUESTS_REJECTED:
            return {
                ...state,
                data: [],
                filteredData: [],
                originalData: [],
                loading: false,
                error: true,
                isAssignedToMe: true,
            };

        case ADD_REQUEST_WORKFLOW_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ADD_REQUEST_WORKFLOW_FULFILLED:
            return {
                ...state,
                data: [action.payload, ...state.data], //todo: set the filtered data IMPORTANTEEEEE
                filteredData: [action.payload, ...state.data],
                originalData: [action.payload, ...state.data],
                loading: false,
                error: false,
            };
        case ADD_REQUEST_WORKFLOW_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case UPDATE_REQUEST_WORKFLOW_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_REQUEST_WORKFLOW_FULFILLED:
            const newData = state.data.map((item) => {
                if (item._id === action.payload._id) {
                    return action.payload;
                }

                return item;
            });

            return {
                ...state,
                data: newData,
                filteredData: newData,
                originalData: newData,
                loading: false,
                error: false,
            };
        case UPDATE_REQUEST_WORKFLOW_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case DELETE_REQUEST_WORKFLOW_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case DELETE_REQUEST_WORKFLOW_FULFILLED:
            const updatedData = state.data.filter((d) => {
                if (d._id !== action.payload.id) {
                    return true;
                }

                return !action.payload.status;
            });
            return {
                ...state,
                data: updatedData,
                filteredData: updatedData,
                originalData: updatedData,
                loading: false,
                error: false,
            };
        case DELETE_REQUEST_WORKFLOW_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case SET_FILTER_REQUEST_WORKFLOW:
            /*const isDuplicateRule = checkIsDuplicateRule(
        action.payload,
        state.filterRules
      );

      if (isDuplicateRule) {
        return state;
      }*/

            //almacena las reglas del filtrado para aplicarlas en el refresh constante
            //const filterRules = [action.payload, ...state.filterRules];

            //filter rules: only keep one rule per item of filter. example:only one status, only one creation date...
            const filterRules = filterNewRules(
                state.filterRules,
                action.payload
            );

            return {
                ...state,
                filterRules,
                data: filterData({
                    data: state.originalData,
                    rules: filterRules,
                }),
            };

        case RESET_FILTER_REQUEST_WORKFLOW:
            return {
                ...state,
                filterRules: [],
                data: state.originalData, //esta es la data original sin filtrar: filteredData hacer refactor de esto
            };

        case SET_FALSE_IS_ASSIGNED_TO_ME:
            return {
                ...state,
                filterRules: [],
                isAssignedToMe: false,
                data: state.originalData, //esta es la data original sin filtrar: filteredData hacer refactor de esto
            };

        case DELETE_FILTER_REQUEST_WORKFLOW:
            //remover ese filtro unicamente
            const filterRulesAux = state.filterRules.filter(
                (item) => item.id !== action.payload.id
            );

            return {
                ...state,
                filterRules: filterRulesAux,
                data: filterData({
                    data: state.originalData,
                    rules: filterRulesAux,
                }),
            };

        case RESET_REQUEST_WORKFLOW:
            return {
                ...state,
                data: state.originalData,
                filteredData: state.originalData,
            };
        case LOCK_SAVE_BUTTON_REQUESTWF:
            return {
                ...state,
                lockSaveButtonRequestWF: !state.lockSaveButtonRequestWF,
            };
        case INCREASE_ROW_COUNTER_IN_TABLE:
            return {
                ...state,
                numberOfRowsInTable: ++state.numberOfRowsInTable,
            };
        case DECREMENT_ROW_COUNTER_IN_TABLE:
            return {
                ...state,
                numberOfRowsInTable: --state.numberOfRowsInTable,
            };
        case RESET_ROW_COUNTER_IN_TABLE:
            return {
                ...state,
                numberOfRowsInTable: 0,
            };
        default:
            return state;
    }
}
