import {
  GET_COMMENT_REQUEST_WORKFLOW_PENDING,
  GET_COMMENT_REQUEST_WORKFLOW_FULFILLED,
  GET_COMMENT_REQUEST_WORKFLOW_REJECTED,
  GET_COMMENT_REQUEST_WORKFLOW_BY_ID_PENDING,
  GET_COMMENT_REQUEST_WORKFLOW_BY_ID_FULFILLED,
  GET_COMMENT_REQUEST_WORKFLOW_BY_ID_REJECTED,
  ADD_COMMENT_REQUEST_WORKFLOW_PENDING,
  ADD_COMMENT_REQUEST_WORKFLOW_FULFILLED,
  ADD_COMMENT_REQUEST_WORKFLOW_REJECTED,
  UPDATE_COMMENT_REQUEST_WORKFLOW_PENDING,
  UPDATE_COMMENT_REQUEST_WORKFLOW_FULFILLED,
  UPDATE_COMMENT_REQUEST_WORKFLOW_REJECTED,
  DELETE_COMMENT_REQUEST_WORKFLOW_PENDING,
  DELETE_COMMENT_REQUEST_WORKFLOW_FULFILLED,
  DELETE_COMMENT_REQUEST_WORKFLOW_REJECTED,
  FILTER_COMMENT_REQUEST_WORKFLOW,
  RESET_COMMENT_REQUEST_WORKFLOW,
} from "../actions/types";

const initialState = {
  data: [],
  dataById: [],
  filteredData: [],
  loading: false,
  error: false,
  selected: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMENT_REQUEST_WORKFLOW_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_COMMENT_REQUEST_WORKFLOW_FULFILLED:
      return {
        ...state,
        data:
          state.data.length > 0
            ? [...state.data, ...JSON.parse(action.payload)]
            : JSON.parse(action.payload),
        loading: false,
        error: false,
      };
    case GET_COMMENT_REQUEST_WORKFLOW_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };

    case ADD_COMMENT_REQUEST_WORKFLOW_PENDING:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ADD_COMMENT_REQUEST_WORKFLOW_FULFILLED:
      return {
        ...state,
        dataById: [...state.data, action.payload],
        loading: false,
        error: false,
      };
    case ADD_COMMENT_REQUEST_WORKFLOW_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_COMMENT_REQUEST_WORKFLOW_BY_ID_REJECTED:
      return {
        ...state,
        dataById: [],
        loading: false,
        error: true,
      };

    case GET_COMMENT_REQUEST_WORKFLOW_BY_ID_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_COMMENT_REQUEST_WORKFLOW_BY_ID_FULFILLED:
      if (action.payload && action.payload.comments) {
        action.payload.comments = action.payload.comments.reverse();
      }
      return {
        ...state,
        dataById: [...state.data, action.payload],
        loading: false,
        error: false,
      };

    case UPDATE_COMMENT_REQUEST_WORKFLOW_PENDING:
      return {
        ...state,
        /*loading: true,*/
        loading: false,
        error: false,
      };
    case UPDATE_COMMENT_REQUEST_WORKFLOW_FULFILLED:
      const newData = state.dataById.map((item) => {
        if (item._id === action.payload._id) {
          if (action.payload && action.payload.comments) {
            action.payload.comments = action.payload.comments.reverse();
          }
          return action.payload;
        }

        return item;
      });

      return {
        ...state,
        dataById: newData,
        loading: false,
        error: false,
      };
    case UPDATE_COMMENT_REQUEST_WORKFLOW_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case DELETE_COMMENT_REQUEST_WORKFLOW_PENDING:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case DELETE_COMMENT_REQUEST_WORKFLOW_FULFILLED:
      const updatedData = state.data.filter((d) => {
        if (d._id !== action.payload.id) {
          return true;
        }

        return !action.payload.status;
      });
      return {
        ...state,
        data: updatedData,
        filteredData: updatedData,
        loading: false,
        error: false,
      };
    case DELETE_COMMENT_REQUEST_WORKFLOW_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    /*case FILTER_COMMENT_REQUEST_WORKFLOW:
      const { filters, rules } = action.payload;
      const filtered = state.data.filter((d) => {
        const conditions = Object.keys(filters).map((filter) =>
          getFilterCondition(
            filters[filter],
            d[rules[filter].filterKey],
            rules[filter].comparationType
          )
        );

        return conditions.every((c) => c);
      });

      return {
        ...state,
        filteredData: filtered,
      };*/
    case RESET_COMMENT_REQUEST_WORKFLOW:
      return {
        ...state,
        data: [],
        dataById: [],
        filteredData: [],
        selected: null,
      };
    default:
      return state;
  }
}
