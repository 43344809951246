import {
    GET_OCUPATIONS_PENDING,
    GET_OCUPATIONS_FULFILLED,
    GET_OCUPATIONS_REJECTED,
    GET_OCUPATIONS
  } from '../actions/types';

  import { dataOcupations } from '../utils/dataSet';

  const initialState = {
    data: dataOcupations,
    loading: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_OCUPATIONS_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_OCUPATIONS_FULFILLED:
        return {
          ...state,
          data: { ...state.data, content: [...action.payload?.ocupationList?.result] },
          loading: false,
          error: false,
        };
      case GET_OCUPATIONS_REJECTED:
        return {
          ...state,
          data: dataOcupations,
          loading: false,
          error: true,
        };
      case GET_OCUPATIONS:
        return {
          ...state,
          data: [],
        };
      default:
        return state;
    }
  }
  