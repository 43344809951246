import { createAsyncAction } from 'redux-promise-middleware-actions';
import {
    insuredApi,
    validateJSONResponse,
    exceptionCodeResponse
} from '../utils/urls';
import {
    getQsiseDashboardPending,
    getQsiseDashboardRejected
} from '../actions/qSiseDashboardAction';

export const getSiseDasboardAPI = createAsyncAction(
    'GET_Q_SISE_DASHBOARD',
    async (token) => {
        const res = await fetch(`${insuredApi}v1/insured/list/policies`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                getQsiseDashboardPending();
                return validateJSONResponse(res);
            })
            .catch((err) => {
                getQsiseDashboardRejected();
                throw exceptionCodeResponse(err);
            });
        return res;
    }
);
