import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import MessageSuccess from '../../components/messages/messageSuccess';
import MessageWarning from '../../components/messages/messageWarning';
import NotificationList from '../../components/notifications/notificationList';
import DrawerConfiguration from '../../components/notifications/drawers/drawerConfiguration';
import ImageSettings from '../../assets/images/settings.svg';

const useStyles = makeStyles((theme) => ({
    listItem: {
        fontSize: 16,
        color: '#333333',
        padding: '1rem',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    listItemSecondary: {
        fontSize: 14
    },
    unread: {
        backgroundColor:
            theme.palette.variants && theme.palette.variants.variant2,
        fontWeight: 600,
        '& > div:last-child': {
            color: '#000000'
        }
    },
    configMenu: {
        width: 350,
        borderTopLeftRadius: 10,
        padding: '20px 15px 20px 15px',
        '& h3': {
            color: '#3B3B3B',
            fontSize: 16
        }
    },
    imageSettings: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));

const UserEventsMain = () => {
    const userEvent = useSelector((state) => state.userEvents);
    const token = useSelector((state) => state.auth.token);

    const [openNotiConfig, setOpenNotiConfig] = useState(false);
    const [orderKey, setOrderKey] = useState('date');
    const classes = useStyles();

    const toggleNotificationConfig = (open) => {
        setOpenNotiConfig(open);
    };

    const handleOrderKeyChange = (evt) => {
        setOrderKey(evt.target.value);
    };

    const orderOptions = [
        { name: 'Fecha', value: 'date' },
        { name: 'No leidos', value: 'unread' },
        { name: 'Nombre', value: 'name' }
    ];

    const getOrderByKey = (type) => {
        switch (type) {
            case 'date': {
                const fn = (a, b) => new Date(b?.sentAt) - new Date(a?.sentAt);
                return fn;
            }
            case 'unread': {
                const fn = (a, b) => (a.read === b.read ? 0 : a.read ? 1 : -1);
                return fn;
            }
            case 'name': {
                const fn = (a, b) => a.message.title > b.message.title;
                return fn;
            }
            default:
                console.log('Invalid type!');
        }
    };

    const notifications =
        orderKey !== ''
            ? userEvent.notifications.sort(getOrderByKey(orderKey))
            : userEvent.notifications;

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 64 }}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                        paddingTop: 25,
                        paddingLeft: 40,
                        paddingBottom: 20,
                        paddingRight: 52
                    }}
                >
                    <Grid item>
                        <FormControl style={{ minWidth: 150 }}>
                            <InputLabel id="sort-control-label">
                                Ordenar por:
                            </InputLabel>
                            <Select
                                labelid="sort-control-label"
                                value={orderKey}
                                onChange={handleOrderKeyChange}
                            >
                                {orderOptions.map((opt) => {
                                    return (
                                        <MenuItem
                                            key={opt.name}
                                            value={opt.value}
                                        >
                                            {opt.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <img
                            src={ImageSettings}
                            className={classes.imageSettings}
                            onClick={() => {
                                toggleNotificationConfig(true);
                            }}
                        />
                    </Grid>
                </Grid>
                <NotificationList token={token} notifications={notifications} />

                <DrawerConfiguration
                    openNotiConfig={openNotiConfig}
                    handleOpenClose={() => toggleNotificationConfig(false)}
                />
            </Grid>
            <MessageSuccess type="userEvents" />
            <MessageWarning />
        </Grid>
    );
};

export default UserEventsMain;
