import React, { Fragment, useState, useCallback, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "@reach/router";
import jwtDecode from "jwt-decode";

import { useStyles } from "./styleMenuClose";
import {
  Grid,
  List,
  ListItemIcon,
  Typography,
  ClickAwayListener,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import {
  Dashboard,
  LocalHospital,
  Notifications,
  Help,
  ChromeReaderMode,
} from "@material-ui/icons";
import {
  menuOptionsAction,
  menuGroupOpenedAction,
} from "../../actions/menuOptionsActions";
import { Can } from "../../authorization/abilities";
// import { Can } from '../../authorization/abilities';

const NavLink = forwardRef(function NavLink(props, ref) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClickOption = (group) => {
    dispatch(menuOptionsAction(group));
    dispatch(menuGroupOpenedAction(""));
  };

  return (
    <Link
      {...props}
      ref={ref}
      getProps={({ isCurrent }) => {
        return {
          className: isCurrent
            ? `${props.className} ${classes.activeLink}`
            : props.className,
          style: {
            color: "red !important",
          },
        };
      }}
      onClick={() => handleClickOption(props.group)}
    >
      {props.children}
    </Link>
  );
});

const OptionMenu = ({ children, option, open, groupOpened, ...props }) => {
  const classes = useStyles();

  return (
    <Grid className={props.className}>
      <Grid
        className={classes.backgroundOptionMenuActive}
        style={{ padding: "15px 15px 7px" }}
      >
        {open && children}
      </Grid>
    </Grid>
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: 10,
    position: "relative",
    zIndex: 9000,
    right: -11,
  },
}))(Tooltip);

const ThemedLinkItemIcon = withStyles()(({ primary, ...props }) => (
  <ListItemIcon style={{ color: primary }} {...props} />
));

export default function SideMenuClose() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const {
    user: {
      permissions: { Backoffice },
    },
  } = jwtDecode(token);
  const menuOptionSelected = useSelector(
    (state) => state.menuOptions.menuOption
  );
  const menuGroupOpened = useSelector(
    (state) => state.menuOptions.menuGroupOpened
  );
  const [openMenu, setOpenMenu] = useState(false);
  const config = useSelector((state) => state.userConfig.data);

  const handleOpenOptions = (group, opt = false) => {
    setOpenMenu(opt);
    menuGroupOpened !== group && dispatch(menuGroupOpenedAction(group));
  };

  const handleGoOption = (group) => {
    dispatch(menuOptionsAction(group));
  };

  const handleClickAway = () => {
    setOpenMenu(false);
    dispatch(menuGroupOpenedAction(""));
  };

  const MenuTitle = ({ selected, title }) => {
    return (
      <Typography
        className={
          selected === menuOptionSelected
            ? `${classes.titleMenuActive}`
            : classes.titleMenuDisabled
        }
      >
        {title}
      </Typography>
    );
  };

  const borderColorMultipleOptions = (group) => {
    if (group === menuOptionSelected) {
      return {
        borderLeft: `4px solid ${config.theme.secondary.variant1}`,
      };
    } else {
      return {
        borderLeft: `4px solid ${config.theme.primary.variant1}`,
      };
    }
  };

  return (
    <ClickAwayListener onClickAway={() => handleClickAway()}>
      <Grid>
        <Grid className={openMenu ? classes.menuExpand : null} />
        <Grid className="menu">
          <List className={classes.navList}>
            <Can do="read" on="Dashboard">
              <Grid className={classes.sectionIconSingle}>
                <ListItemIcon onClick={() => handleGoOption("dashboard")}>
                  <HtmlTooltip
                    placement="right"
                    title={
                      <Fragment>
                        <div className={classes.arrowUp} />
                        <Typography color="inherit">Inicio</Typography>
                      </Fragment>
                    }
                  >
                    <NavLink
                      to="dashboard"
                      className={classes.activeLinkSingle}
                    >
                      <Dashboard
                        className={
                          menuOptionSelected === "dashboard"
                            ? classes.iconActive
                            : classes.icon
                        }
                      />
                    </NavLink>
                  </HtmlTooltip>
                </ListItemIcon>
              </Grid>
            </Can>
            <Can do="read" on="MedicalNetWork">
              <Grid className={classes.sectionIconSingle}>
                <ListItemIcon onClick={() => handleGoOption("medical-network")}>
                  <HtmlTooltip
                    placement="right"
                    title={
                      <Fragment>
                        <div className={classes.arrowUp} />
                        <Typography color="inherit">Red Médica</Typography>
                      </Fragment>
                    }
                  >
                    <NavLink
                      to="medical-network"
                      className={classes.activeLinkSingle}
                    >
                      <LocalHospital
                        className={
                          menuOptionSelected === "medical-network"
                            ? classes.iconActive
                            : classes.icon
                        }
                      />
                    </NavLink>
                  </HtmlTooltip>
                </ListItemIcon>
              </Grid>
            </Can>
            <Can do="read" on="Notifications">
              <Grid className={classes.sectionIconSingle}>
                <ListItemIcon onClick={() => handleGoOption("notifications")}>
                  <HtmlTooltip
                    placement="right"
                    title={
                      <Fragment>
                        <div className={classes.arrowUp} />
                        <Typography color="inherit">Notificaciones</Typography>
                      </Fragment>
                    }
                  >
                    <NavLink
                      to="notifications"
                      className={classes.activeLinkSingle}
                    >
                      <Notifications
                        className={
                          menuOptionSelected === "notifications"
                            ? classes.iconActive
                            : classes.icon
                        }
                      />
                    </NavLink>
                  </HtmlTooltip>
                </ListItemIcon>
              </Grid>
            </Can>
            <Can do="read" on="FAQ">
              <Grid className={classes.sectionIconSingle}>
                <ListItemIcon onClick={() => handleGoOption("faq")}>
                  <HtmlTooltip
                    placement="right"
                    title={
                      <Fragment>
                        <div className={classes.arrowUp} />
                        <Typography color="inherit">Ayuda</Typography>
                      </Fragment>
                    }
                  >
                    <NavLink to="faq" className={classes.activeLinkSingle}>
                      <Help
                        className={
                          menuOptionSelected === "faq"
                            ? classes.iconActive
                            : classes.icon
                        }
                      />
                    </NavLink>
                  </HtmlTooltip>
                </ListItemIcon>
              </Grid>
            </Can>
            <Can do="read" on="News">
              <Grid className={classes.sectionIconSingle}>
                <ListItemIcon onClick={() => handleGoOption("news")}>
                  <HtmlTooltip
                    placement="right"
                    title={
                      <Fragment>
                        <div className={classes.arrowUp} />
                        <Typography color="inherit">Noticias</Typography>
                      </Fragment>
                    }
                  >
                    <NavLink to="news" className={classes.activeLinkSingle}>
                      <ChromeReaderMode
                        className={
                          menuOptionSelected === "news"
                            ? classes.iconActive
                            : classes.icon
                        }
                      />
                    </NavLink>
                  </HtmlTooltip>
                </ListItemIcon>
              </Grid>
            </Can>
          </List>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
