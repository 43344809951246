import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    rightDrawer: {
      width: 450,
      height: '100%',
      zIndex: '9000 !important',
      transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: '1sg'
      }),
      ['@media (max-width:426px)']: { // eslint-disable-line no-useless-computed-key
          width: '100%',
          zIndex: '9000 !important'
      },
      borderTopLeftRadius: 10,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
}))