import {
  GET_BANK_LIST_PENDING,
  GET_BANK_LIST_FULFILLED,
  GET_BANK_LIST_REJECTED,
  GET_CARD_LIST_PENDING,
  GET_CARD_LIST_FULFILLED,
  GET_CARD_LIST_REJECTED,
  GET_PAYMENT_METHOD_LIST_PENDING,
  GET_PAYMENT_METHOD_LIST_FULFILLED,
  GET_PAYMENT_METHOD_LIST_REJECTED,
  UPDATE_PAYMENT_METHOD_PENDING,
  UPDATE_PAYMENT_METHOD_FULFILLED,
  UPDATE_PAYMENT_METHOD_REJECTED,
  UPDATE_PAYMENT_METHOD_ERROR,
} from "../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export const bankListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_LIST_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_BANK_LIST_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_BANK_LIST_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export const cardListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARD_LIST_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_CARD_LIST_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_CARD_LIST_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export const paymentMethodListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHOD_LIST_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_PAYMENT_METHOD_LIST_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_PAYMENT_METHOD_LIST_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updatePaymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_METHOD_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATE_PAYMENT_METHOD_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case UPDATE_PAYMENT_METHOD_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
