import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  container: {
    width: props => props.desktop ? 382 : 'calc(100vw - 18px)',
    marginTop: '.5rem',
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&.mobile::-webkit-scrollbar': {
      width: 0,
      background: 'transparent'
    },
    '& > *': {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.secondary.light,
      color: '#fff',
      letterSpacing: 0.25,
      fontSize: 14
    }
  },
  placeholder: {
    backgroundColor: '#FFF',
    border: '1px dashed #D4D4D4',
    borderRadius: 16,
    color: '#BDBDBD',
    letterSpacing: 0.25,
    fontSize: 14
  }
}));

const ChipList = ({ chips = [], handleDelete, onToggle, disabled = false, deleteable = false, ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={`${classes.container} ${!props.desktop ? 'mobile' : ''}`}>
      {chips.length > 0
        ? chips.map((chip, index) => deleteable ? (
            <Chip key={index} size="small" label={chip.value} onDelete={() => handleDelete(chip)} />
          ) : (
            <Chip key={index} size="small" label={chip.value} />
          )
        )
        : <Chip disabled={disabled} size="small" label="Agrega un filtro" variant="outlined" className={classes.placeholder} onClick={(e) => onToggle(e.currentTarget, true)} color="primary" />
      }
    </div>
  );
};

export default ChipList;
