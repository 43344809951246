import { getSiseItemsPolicyAPI } from '../sevices/itemsPolicyService';
import {
    GET_Q_SISE_ITEMSPOLICY_REJECTED,
    GET_Q_SISE_ITEMSPOLICY_PENDING
} from './types';

export const getItemsPolicyPending = () => {
    return {
        type: GET_Q_SISE_ITEMSPOLICY_PENDING
    };
};

export const getItemsPolicy = (token, id_pv) => async (dispatch) => {
    await dispatch(getSiseItemsPolicyAPI(token, id_pv));
};

export const getItemsPolicyRejected = () => {
    return {
        type: GET_Q_SISE_ITEMSPOLICY_REJECTED
    };
};
