var formatNumber = {
    separador: ',', // separador para los miles
    sepDecimal: '.', // separador para los decimales
    formatear: function (num) {
        num += '';
        let splitStr = num.split(this.separador);
        let splitLeft = splitStr[0];
        let splitRight =
            splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        let regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
            splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return this.simbol + splitLeft + splitRight;
    },
    new: function (num, simbol) {
        this.simbol = simbol || '';
        if (this.formatear(num).split(this.sepDecimal).length < 2) {
            return this.formatear(num) + `${this.sepDecimal}00`;
        }
        return this.formatear(num);
    }
};

export default formatNumber;
