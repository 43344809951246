import {
  GET_ACCESORIES_PENDING,
  GET_ACCESORIES_FULFILLED,
  GET_ACCESORIES_REJECTED,
  PATCH_ACCESORIES_ERROR,
} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCESORIES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_ACCESORIES_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_ACCESORIES_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case PATCH_ACCESORIES_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}
