import { createAsyncAction } from "redux-promise-middleware-actions";
import axios from "axios";
import {
    quotationUrlApi,
    exceptionCodeResponse,
    validateJSONResponse,
    repositoryCode,
} from "../utils/urls";

export const uploadFilesAPI = createAsyncAction(
    "UPLOAD_FILES",
    async (token, bodyReq, dispatch) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        const formData = new FormData();
        formData.append("file", bodyReq.files);
        formData.append("fileName", bodyReq.fileName);
        formData.append("originalName", bodyReq.originalName);
        formData.append("requestID", bodyReq.requestID);
        formData.append("insuredName", bodyReq.insuredName);
        formData.append("repositoryCode", bodyReq.repositoryCode);
        formData.append("permanentlySave", bodyReq.permanentlySave);
        formData.append(
            "aditionalDetails",
            JSON.stringify(bodyReq.aditionalDetails)
        );

        const config = {
            headers,
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );

                /*setFileUploadPercentage(percentCompleted);*/
                //console.log("progressEvent", progressEvent);
                //console.log("percentCompleted", percentCompleted);
            },
        };

        const res = await axios
            .post(`${quotationUrlApi}file/upload`, formData, config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });

        return res;
    }
);

export const replaceFilesAPI = createAsyncAction(
    "REPLACE_FILES",
    async (token, bodyReq, dispatch) => {
        console.log("Aqui se llama a REPLACE_FILES");

        const body = JSON.stringify({
            filesPath: bodyReq,
            repositoryCode,
        });

        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        const res = await fetch(`${quotationUrlApi}file/delete`, {
            method: "DELETE",
            headers,
            body,
        })
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });

        return res;
    }
);

export const saveTemporalFilesAPI = createAsyncAction(
    "SAVE_TEMPORAL_FILES",
    async (token, bodyReq, dispatch) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        const body = JSON.stringify(bodyReq);
        const res = await fetch(`${quotationUrlApi}file/move`, {
            method: "POST",
            headers,
            body,
        })
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);
