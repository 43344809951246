import {
    GET_AGENT_LIST,
    GET_AGENT_LIST_FULFILLED,
    GET_AGENT_LIST_REJECTED,
    GET_AGENT_LIST_PENDING
  } from "../actions/types";
  
  const initialState = {
    data: [],
    loading: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_AGENT_LIST_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_AGENT_LIST_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_AGENT_LIST_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true,
        };
      case GET_AGENT_LIST:
        return {
          ...state,
          data: [],
        };
      default:
        return state;
    }
  }
  