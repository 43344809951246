import axios from "axios";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { getToken } from "../utils/index";
import { showError } from "../actions/notificationActions";
import { showModal } from "../actions/modalActions";
import { authUrlApi } from "../utils/urls";

export const getDataAgentBank = createAsyncAction(
  "GET_AGENT_BANK_INFO",
  async (token = null, dispatch) => {
    const headers = { Authorization: `Bearer ${token}` };

    const response = axios
      .get(`${authUrlApi}/agentBank/sise`, { headers })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        dispatch(showModal(true));
        console.error(error);
      });
    return response;
  }
);

export const createDataAgentBank = createAsyncAction(
  "CREATE_AGENT_BANK_INFO",
  async (body, dispatch) => {
    const token = getToken();
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.post(`${authUrlApi}/agentBank`, body, {
        headers,
      });
      return response.data;
    } catch (error) {
      const { data } = error.response?.data;
      dispatch(showError(data));
      return data;
    }
  }
);

export const updateDataAgentBank = createAsyncAction(
  "UPDATE_AGENT_BANK_INFO",
  async (body, dispatch) => {
    const token = getToken();
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.put(`${authUrlApi}/agentBank/update`, body, {
        headers,
      });
      return response.data;
    } catch (error) {
      const { data } = error.response?.data;
      dispatch(showError(data));
      return data;
    }
  }
);
