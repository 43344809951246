import {
    getDataAgentBank,
    createDataAgentBank,
    updateDataAgentBank
} from '../sevices/agentBankService';

export const getDataAgentBankApi = (token) => async (dispatch) => {
    await dispatch(getDataAgentBank(token, dispatch))
};

export const createDataAgentBankApi = (data) => async (dispatch) => {
    return await dispatch(createDataAgentBank(data, dispatch))
}

export const updateDataAgentBankApi = (data) => async (dispatch) => {
    return await dispatch(updateDataAgentBank(data, dispatch))
}