import {
  GET_CONFIGURATION,
  GET_CONFIGURATION_FULFILLED,
  GET_CONFIGURATION_REJECTED,
  GET_CONFIGURATION_PENDING
} from "../actions/types";

const initialState = {
  data: {},
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONFIGURATION_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_CONFIGURATION_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_CONFIGURATION_REJECTED:
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
      };
    case GET_CONFIGURATION:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
}
