import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    sectionAvatar: {
        paddingTop: 35,
        paddingBottom: 35,
        position: 'relative',
        '& .nameProfile': {
            paddingTop: 21,
            color: theme.palette.secondary.main
        },
        '& .codeBroker': {
            fontSize: 15
        },
        '& .iconUpload': {
            backgroundColor: '#6C63FF'
        }
    },
    photoProfile: {
        height: 155,
        width: 155,
        backgroundColor: theme.palette.primary.main
    },
    spaceInput: {
        paddingBottom: 20,
        width: '100%'
    },
    sectBtnSave: {
        paddingTop: 20,
        '& .btn-save': {
            backgroundColor: '#6C63FF'
        }
    },
    imgMessageError: {
        color: 'red',
        position: 'absolute',
        top: 10
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    btn: {
        backgroundColor: '#6C63FF',
    },
    buttonProgress: {
        color: theme.palette.primary.dark,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))