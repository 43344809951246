import {
  GET_MEDICAL_NETWORK_SISE,
  GET_MEDICAL_NETWORK_PENDING,
  GET_MEDICAL_NETWORK_FULFILLED,
  GET_MEDICAL_NETWORK_REJECTED,
  CLEAR_MEDICAL_NETWORK,
  SELECT_MEDICAL_SERVICE,
  SEARCH_MEDICAL_NETWORK,
} from "../actions/types";

const initialState = {
  data: [],
  count: 0,
  searching: "",
  loading: false,
  error: false,
  selected: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_MEDICAL_NETWORK_SISE}_PENDING`:
    case GET_MEDICAL_NETWORK_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SEARCH_MEDICAL_NETWORK:
      return {
        ...state,
        searching: action.payload,
      };
    case `${GET_MEDICAL_NETWORK_SISE}_FULFILLED`:
    case GET_MEDICAL_NETWORK_FULFILLED:
      return {
        ...state,
        data:
          state.data.length > 0
            ? [...state.data, ...action.payload.response]
            : action.payload.response,
        count: action.payload.count,
        loading: false,
        error: false,
      };

    case `${GET_MEDICAL_NETWORK_SISE}_REJECTED`:
    case GET_MEDICAL_NETWORK_REJECTED:
      return {
        ...state,
        data: [],
        count: 0,
        loading: false,
        error: true,
      };
    case CLEAR_MEDICAL_NETWORK:
      return {
        data: [],
        count: 0,
        loading: false,
        error: false,
      };
    case SELECT_MEDICAL_SERVICE:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
}
