import { createAsyncAction } from 'redux-promise-middleware-actions';
import { urlBase } from '../utils/urls';

export const getDocumentsListAPI = createAsyncAction(
    'GET_DOCUMENTS_LIST',
    async () => {
        const res = await fetch(`${urlBase}listDocuments`, {
            method: 'post',
            headers: {
                'Content-type': 'application/json'
            }
        })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};
