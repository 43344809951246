import React, { useMemo, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useStyles } from '../quoter/styles';
import { Grid, Button } from '@material-ui/core';
import { RenderSearchSelectFieldSimple } from '../quoter/quoteInputs/index';
import { makeStyles } from '@material-ui/core/styles';

const useStylesForm = makeStyles((theme) => ({
    provinceForm: {
        width: '400px',
        '@media (max-width: 600px)': {
            // eslint-disable-line no-useless-computed-key
            width: '66vw'
        },
        '& button': {
            height: '50px !important'
        }
    }
}));

const validate = (values) => {
    const errors = {};
    const requiredFields = [];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

export function SearchProvinceForm({
    handleSubmitItem,
    handleCancel,
    streets
}) {
    const classes = useStyles();
    const classesForm = useStylesForm();
    const [valueCodigoPostal, setValueCodigoPostal] = useState('');
    const handleSubmits = () => {
        const selectedStreet = streets.find(
            (street) => street.CPLocation == valueCodigoPostal
        );
        handleSubmitItem(selectedStreet);
    };

    const options = useMemo(() => {
        return streets.map((street) => ({
            value: street['CPLocation'],
            name: `${street['street']} ${street['nro_altura_desde']} hasta ${street['nro_altura_hasta']} - ${street['CPLocation']}.`
        }));
    }, []);

    const handleChangeCustomInput = (valueCodigoPostal) => {
        const selectedStreet = streets.find(
            (street) => street.CPLocation == valueCodigoPostal
        );
        handleSubmitItem(selectedStreet);
    };

    return (
        <form className={classesForm.provinceForm}>
            <Grid container direction="row">
                <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ padding: 8, position: 'relative' }}
                    className="select-seatch__list_modal"
                >
                    <label className={classes.styledLabel}>Código Postal</label>
                    <Field
                        name={'selectedCodigoPostal'}
                        label={'Código Postal'}
                        component={RenderSearchSelectFieldSimple}
                        custom={{ tabIndex: 6 }}
                        classes={classes.fontSizeLabel}
                        options={options}
                        printOptions="always"
                        handleChangeSelect={handleChangeCustomInput}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '25vh' }}>
                <Grid
                    container
                    justify="flex-end"
                    style={{ marginTop: 32, padding: 8 }}
                >
                    <Button
                        variant="outlined"
                        onClick={handleCancel}
                        style={{ marginRight: 16 }}
                    >
                        Cancelar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default reduxForm({ form: 'searchProvinceForm', validate })(
    SearchProvinceForm
);
