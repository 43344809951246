import React, { useReducer, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

import MapIconMedicalCenter from "../../assets/images/map_icon_medical_center.svg";
import MedicalNetworkOptions from "../medicalNetworkCardOptions";
import InfoWindowEx from "./InfoWindowEx";
import { googleMapsKey } from "../../utils/urls";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    "& .gm-style-iw-d": {
      overflow: "auto !important",
    },
    "& .gm-style-iw-c": {
      padding: 0,
    },
    "& .gm-ui-hover-effect": {
      right: "28px !important",
      top: "17px !important",
      width: "20px !important",
      height: "20px !important",
      padding: "0px !important",
      color: "#fff !important",
      flex: "0 0 auto !important",
      overflow: "visible !important",
      fontSize: "1.5rem !important",
      textAlign: "center !important",
      transition:
        "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
      borderRadius: "50% !important",
      border: "0px !important",
      cursor: "pointer !important",
      display: "inline-flex !important",
      outline: "0 !important",
      alignItems: "center !important",
      verticalAlign: "middle !important",
      appearance: "none !important",
      justifyContent: "center !important",
      textDecoration: "none !important",
      opacity: "1 !important",
      backgroundColor: `${theme.palette.primary.main} !important`,
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "& img": {
        width: "20px !important",
        height: "20px !important",
        filter: "invert(1)",
      },
    },
  },
}));

const MapContainer = ({
  data = [],
  google,
  selected,
  handleSelect,
  handleOptionsToggle,
  desktop,
  firstLocaction,
  search,
}) => {
  const classes = useStyles();
  const medicalNetworkTypes = useSelector(
    (selector) => selector.medicalNetworkTypes
  );
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      activeMarker: null,
      center: {
        lat: selected ? selected.adress.location.lat : -34.6041137,
        lng: selected ? selected.adress.location.lng : -58.3884672,
      },
    }
  );

  /* const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null); */
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setState({
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    });
  });

  const onMarkerClick = (props, marker, e) => {
    handleSelect(props.data ? props.data : props);
    handleOptionsToggle(true);
    setState({ activeMarker: null });
    /* setShowInfoWindow(true);
    setActiveMarker(marker);
    setSelectedPlace(props.data); */
  };

  useEffect(() => {
    if (firstLocaction) {
      setState({
        center: { lat: firstLocaction.lat, lng: firstLocaction.lng },
      });
    }
  }, [firstLocaction]);

  const zoom = useMemo(
    () => (desktop ? 14 : firstLocaction ? 7 : 10),
    [desktop, firstLocaction]
  );

  // const initialCenter = { lat: selected ? selected.adress.location.lat : -34.6041137, lng: selected ? selected.adress.location.lng : -58.3884672 };
  return (
    <div>
      <Map
        initialCenter={state.center}
        center={selected ? selected.adress.location : state.center}
        google={google}
        zoom={zoom}
        className={classes.mapContainer}
      >
        {!medicalNetworkTypes.block &&
          data.map((pos, i) => (
            <Marker
              key={i}
              onClick={onMarkerClick}
              data={pos}
              position={pos.adress.location}
              icon={{
                url: MapIconMedicalCenter,
                anchor: new google.maps.Point(16, 16),
                scaledSize: new google.maps.Size(32, 32),
              }}
            />
          ))}
        {desktop && !medicalNetworkTypes.block && (
          <InfoWindowEx
            marker={state.activeMarker}
            visible={selected !== null}
            position={
              selected
                ? {
                    lat: selected?.adress.location.lat,
                    lng: selected?.adress.location.lng,
                  }
                : null
            }
          >
            <div style={{ width: 360 }}>
              <MedicalNetworkOptions selected={selected} desktop />
            </div>
          </InfoWindowEx>
        )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: googleMapsKey,
  language: "es",
})(MapContainer);
