import React from 'react';
import Dashboard from './dashboard';
import DashboardLibra from './dashLibra';
import { INSURED_CLIENT } from '../../utils/urls';

function Wrapper() {
    console.log('INSURED_CLIENT', INSURED_CLIENT);
    return (
        <>{INSURED_CLIENT === 'LIBRA' ? <DashboardLibra /> : <Dashboard />}</>
    );
}

export default Wrapper;
