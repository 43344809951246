import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  SvgIcon,
  Drawer,
  IconButton,
  Container,
} from "@material-ui/core";
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  Close,
} from "@material-ui/icons";
import MUICarousel from "react-material-ui-carousel";
import { useStyles } from "./styles";
import QsiseExecuteWrapper from "qsise-execute";
import { getQsiseDashboard } from "../../actions/qSiseDashboardAction";
import { getQsiseSinister } from "../../actions/qSiseSinisterAction";
import ButtonAction from "../../components/buttonAction";
import {
  ID_Q_SISE_CARTERA,
  SISE_URL,
  ID_Q_SISE_DETAILS_SINISTER,
  ID_Q_SISE_FEES_PAYMENTS,
  quotationUrlBase,
  INSURED_CLIENT,
} from "../../utils/urls";
import CardInsured from "../../components/widgets/cards/insured";
import CardPay from "../../components/widgets/cards/payCustom";
import CardComplaints from "../../components/widgets/cards/complaints";
import { general } from "./../../utils/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { getDataUserLoggeIn } from "../../actions/userInfoActions";
import { Alert } from "@material-ui/lab";
import { getDataToken } from "../../utils/index";
import { navigate } from "@reach/router";
import { getAllActions } from "../../actions/actions";
import { BannersNews } from "news-list";
import { getAllRiskType } from "../../actions/riskTypeActions";

const Dashboard = () => {
  SwiperCore.use([Navigation, Pagination]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const risktypes = useSelector((state) => state.risktype);
  const [openDetails, setOpenDetails] = useState(false);
  const [openDetailsSinister, setOpenDetailsSinister] = useState(false);
  const [policy, setPolicy] = useState(null);
  const [sinister, setSinister] = useState(null);
  const [sinisterPolicyId, setSinisterPolicyId] = useState(null);
  const config = useSelector((state) => state.userConfig.data);
  const { loading: loadingDashboard, ...qSiseDashboard } = useSelector(
    (state) => state.qSiseDashboard
  );
  const [dataToken, setDataToken] = useState();
  const qSiseSinister = useSelector((state) => state.qSiseSinister);
  const userInfo = useSelector((state) => state.userInfo);
  const actions = useSelector((state) => state.actions);
  const auth = useSelector((state) => state.auth);
  const [, setDataDashboard] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [dataSinister, setDataSinister] = useState([]);
  const [openPaymentsDetails, setOpenPaymentsDetails] = useState(false);
  const handlePolicyDrawer = (policyNumber) => {
    setPolicy(policyNumber);
    setOpenDetails(true);
  };

  const executeInitQsiseDashboard = async () => {
    await dispatch(getQsiseDashboard(token));
    await dispatch(getQsiseSinister(token));
  };

  useEffect(() => {
    if (Object.keys(qSiseDashboard.data).length === 0) {
      executeInitQsiseDashboard();
    }
    if (auth && !auth.token) {
      dispatch(getDataUserLoggeIn(auth.token));
    }

    if (actions && actions.data && !actions.data.length) {
      dispatch(getAllActions(token, { active: true }));
    }

    if (risktypes && risktypes.data && !risktypes.data.length) {
      dispatch(getAllRiskType(auth?.token));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      qSiseDashboard &&
      qSiseDashboard.data &&
      qSiseDashboard.data.data &&
      qSiseDashboard.data.data.listaPolizas &&
      qSiseDashboard.data.data.listaPolizas.result &&
      qSiseDashboard.data.data.listaPolizas.result.length
    ) {
      setDataDashboard(qSiseDashboard.data.data.listaPolizas.result);
      setPolicies(qSiseDashboard.data.data.listaPolizas.result);
    }
    if (
      qSiseSinister &&
      qSiseSinister.data &&
      qSiseSinister.data.data &&
      qSiseSinister.data.data.listaSiniestros &&
      qSiseSinister.data.data.listaSiniestros.result &&
      qSiseSinister.data.data.listaSiniestros.result.length
    ) {
      setDataSinister(qSiseSinister.data.data.listaSiniestros.result);
    }
  }, [qSiseDashboard, qSiseSinister]);

  useEffect(() => {
    if (auth && auth.token) {
      const _dataToken = getDataToken(auth?.token);
      setDataToken(_dataToken);
    }
  }, []);

  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  const policiesSort = policies?.sort((a, b) => {
    if (
      a.proxima_cuota_estado === "VENCIDA" &&
      b.proxima_cuota_estado !== "VENCIDA"
    ) {
      return -1;
    }
    if (
      a.proxima_cuota_estado === "VENCIDA" &&
      b.proxima_cuota_estado === "VENCIDA" &&
      moment(moment(a.proxima_cuota_vencimiento).format("YYYY-MM-DD")).isBefore(
        moment(b.proxima_cuota_vencimiento).format("YYYY-MM-DD")
      )
    ) {
      return -1;
    }

    if (
      a.proxima_cuota_estado !== "VENCIDA" &&
      b.proxima_cuota_estado !== "VENCIDA" &&
      a.proxima_cuota_estado === "PENDIENTE" &&
      b.proxima_cuota_estado === "PENDIENTE" &&
      moment(moment(a.proxima_cuota_vencimiento).format("YYYY-MM-DD")).isBefore(
        moment(b.proxima_cuota_vencimiento).format("YYYY-MM-DD")
      )
    ) {
      return -1;
    }

    return 0;
  });

  const policiesWithPayment = policiesSort.filter((policy) => {
    const roles = policy.rol?.split(",")?.map((rol) => rol?.trim());
    return roles?.includes("Pagador");
  });

  const handleDetailSinister = (sinisterNumber, sinisterIdPV) => {
    setSinisterPolicyId(sinisterIdPV);
    setSinister(sinisterNumber);
    setOpenDetailsSinister(true);
  };

  const handlePaymentDrawer = (policyNumber) => {
    setPolicy(policyNumber);
    setOpenPaymentsDetails(true);
  };

  const query = { query: "visibility=/1757/i" };
  return (
    <>
      {/* Banner Slider */}

      {/* Banner Slider */}
      <BannersNews
        token={token}
        url={quotationUrlBase}
        query={query}
        userInfo={userInfo}
      />
      <Container className={classes.content} maxWidth={"lg"}>
        {/* Cards Policies */}
        <Typography className={classes.subtitle}>Mis seguros</Typography>
        <>
          <div className={classes.rootSwiper}>
            <div className="swiper-left-arrow">
              <KeyboardArrowLeft color="primary" />
            </div>
            <Swiper
              className={classes.sliderInsured}
              spaceBetween={21}
              slidesPerGroupSkip={1}
              slidesOffsetBefore={0}
              navigation={{
                nextEl: ".swiper-right-arrow",
                prevEl: ".swiper-left-arrow",
                clickable: true,
              }}
              breakpoints={{
                700: {
                  slidesPerView: 2,
                  slidesOffsetBefore: 0,
                },
                1150: {
                  slidesPerView: 4,
                  slidesOffsetBefore: 0,
                },
              }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper, "SWIPER")}
              modules={[Navigation, Pagination]}
            >
              {loadingDashboard &&
                Array(4)
                  .fill(true)
                  .map((item, index) => (
                    <SwiperSlide key={index}>
                      <CardInsured loading={item} />
                    </SwiperSlide>
                  ))}
              {!loadingDashboard &&
                policies &&
                policies.length &&
                policies?.map((policy, index) => (
                  <SwiperSlide key={index}>
                    <CardInsured
                      risktype={
                        risktypes && risktypes.data && risktypes.data.length
                          ? risktypes.data.find((e) => {
                              if (e.description.length < policy.ramo.length) {
                                return policy.ramo.includes(e.description);
                              }
                              return e.description.includes(policy.ramo)
                                ? e
                                : null;
                            })
                          : null
                      }
                      loading={loadingDashboard || risktypes.loading}
                      policy={policy}
                      handleOpenDrawer={() => handlePolicyDrawer(policy.id_pv)}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-right-arrow">
              <KeyboardArrowRight color="primary" />
            </div>
          </div>
        </>

        {!loadingDashboard && policies && !policies.length && (
          <Alert severity="info">Sin pólizas para mostrar</Alert>
        )}

        <Grid
          container
          spacing={2}
          justify="space-between"
          alignItems="stretch"
        >
          <Grid className={classes.item} item xs={12} sm={6}>
            <Typography className={classes.subtitle}>Pagos</Typography>
            {!loadingDashboard &&
            policiesWithPayment &&
            policiesWithPayment.length ? (
              <div className={classes.swiperPay}>
                <div className="pay-left-arrow">
                  <KeyboardArrowLeft color="primary" />
                </div>
                <Swiper
                  spaceBetween={21}
                  slidesPerGroupSkip={1}
                  slidesOffsetBefore={0}
                  navigation={{
                    nextEl: ".pay-right-arrow",
                    prevEl: ".pay-left-arrow",
                    clickable: true,
                  }}
                  breakpoints={{
                    1150: {
                      slidesPerView: 1,
                      slidesOffsetBefore: 0,
                    },
                  }}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper, "SWIPER")}
                  modules={[Navigation, Pagination]}
                >
                  {policiesWithPayment?.map((policy, key) => (
                    <SwiperSlide key={key}>
                      <CardPay
                        policy={policy}
                        handleOpenDrawer={() =>
                          handlePaymentDrawer(policy.id_pv)
                        }
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="pay-right-arrow">
                  <KeyboardArrowRight color="primary" />
                </div>
              </div>
            ) : null}
            {!loadingDashboard &&
              policiesWithPayment &&
              !policiesWithPayment.length && (
                <Alert severity="info">Sin pagos para mostrar</Alert>
              )}
          </Grid>
          {INSURED_CLIENT.toLowerCase() !== "abank" ? (
            <Grid className={classes.item} item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Siniestros</Typography>
              <MUICarousel
                classes={{
                  root: classes.carouselComplaint,
                }}
                NextIcon={<KeyboardArrowRight />}
                PrevIcon={<KeyboardArrowLeft />}
                navButtonsProps={{
                  style: {
                    padding: 0,
                    backgroundColor: "transparent",
                    color: "#979797",
                  },
                }}
                navButtonsAlwaysVisible={true}
                cycleNavigation={false}
                indicators={false}
                autoPlay={false}
              >
                {dataSinister.map((sinister, key) => (
                  <CardComplaints
                    sinister={sinister}
                    position={key + 1}
                    total={dataSinister.length}
                    key={key}
                    handleDetailSinister={() =>
                      handleDetailSinister(
                        sinister.numero_siniestro,
                        sinister.id_pv
                      )
                    }
                  />
                ))}
              </MUICarousel>
            </Grid>
          ) : null}
        </Grid>
        {/* Button Call to Action*/}
        <div style={{ marginTop: 28 }}>
          <Grid container spacing={2}>
            {actions &&
              actions.data &&
              actions.data
                .sort((prev, current) => prev - current)
                .map((item, index) => (
                  <Grid key={index} item xs={12} sm>
                    <ButtonAction
                      title={item?.name}
                      onClick={() =>
                        item.pathType === "relative"
                          ? openNewTab(item.url)
                          : navigate(item.url)
                      }
                      isMenu={item?.isMenu}
                      ariaControls={item?.ariaControls}
                      listMenu={item?.listMenu}
                      icon={
                        <SvgIcon viewBox="0 0 20 20">
                          {general[item?.icon]}
                        </SvgIcon>
                      }
                    />
                  </Grid>
                ))}
          </Grid>
        </div>
        {policy && openDetails && (
          <Drawer
            ModalProps={{
              disableBackdropClick: true,
              hideBackdrop: true,
            }}
            anchor="right"
            open={openDetails}
            classes={{
              paper: classes.filterMenu,
            }}
            onClose={() => {
              setOpenDetails(false);
            }}
          >
            <div className={classes.closeBtn}>
              <IconButton
                ariaClose-label="close details"
                onClick={() => {
                  setOpenDetails(!openDetails);
                }}
              >
                <Close style={{ color: "#fff" }} />
              </IconButton>
            </div>
            <QsiseExecuteWrapper
              token={token}
              endpoint={SISE_URL + "/api"}
              qSiseID={ID_Q_SISE_CARTERA}
              theme={config?.theme}
              externalData={{
                email: dataToken?.user?.email || "icn_napolitana@yopmail.com",
              }}
              options={{
                credentials: {
                  username: "iconnectance@sistran.com",
                  password: "digital",
                },
                variables: {
                  policyId: policy,
                  insuranceCode: dataToken?.user?.insuredCode,
                },
                qSiseIndex: 0,
              }}
            />
          </Drawer>
        )}
        {sinister && openDetailsSinister && !qSiseSinister.loading && (
          <Drawer
            ModalProps={{
              disableBackdropClick: true,
              hideBackdrop: true,
            }}
            anchor="right"
            open={openDetailsSinister}
            classes={{
              paper: classes.filterMenu,
            }}
            onClose={() => {
              setOpenDetailsSinister(false);
            }}
          >
            <div className={classes.closeBtn}>
              <IconButton
                ariaClose-label="close details"
                onClick={() => {
                  setOpenDetailsSinister(!openDetailsSinister);
                }}
              >
                <Close style={{ color: "#fff" }} />
              </IconButton>
            </div>
            <QsiseExecuteWrapper
              token={token}
              endpoint={SISE_URL + "/api"}
              qSiseID={ID_Q_SISE_DETAILS_SINISTER}
              theme={config?.theme}
              options={{
                credentials: {
                  username: "iconnectance@sistran.com",
                  password: "digital",
                },
                variables: {
                  policyId: sinisterPolicyId,
                  insuredCode: dataToken?.user?.insuredCode,
                  nroSinister: sinister,
                },
                qSiseIndex: 0,
              }}
            />
          </Drawer>
        )}
        {policy && openPaymentsDetails && (
          <Drawer
            ModalProps={{
              disableBackdropClick: true,
              hideBackdrop: true,
            }}
            anchor="right"
            open={openPaymentsDetails}
            classes={{
              paper: classes.filterMenu,
            }}
            onClose={() => {
              setOpenPaymentsDetails(false);
            }}
          >
            <div className={classes.closeBtn}>
              <IconButton
                ariaClose-label="close details"
                onClick={() => {
                  setOpenPaymentsDetails(!openPaymentsDetails);
                }}
              >
                <Close style={{ color: "#fff" }} />
              </IconButton>
            </div>
            <QsiseExecuteWrapper
              token={token}
              endpoint={SISE_URL + "/api"}
              qSiseID={ID_Q_SISE_FEES_PAYMENTS}
              theme={config?.theme}
              options={{
                credentials: {
                  username: "iconnectance@sistran.com",
                  password: "digital",
                },
                variables: {
                  policyId: policy,
                  insuranceCode: dataToken?.user?.insuredCode,
                },
                qSiseIndex: 0,
              }}
            />
          </Drawer>
        )}
      </Container>
    </>
  );
};

export default React.memo(Dashboard);
