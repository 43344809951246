import {
    MENU_OPTION,
    GROUP_MENU_OPENED
} from '../actions/types';

const initialState = {
    menuOption: '',
    menuGroupOpened: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MENU_OPTION:
            return {
                ...state,
                menuOption: action.payload
            };
        case GROUP_MENU_OPENED:
            return {
                ...state,
                menuGroupOpened: action.payload
            };
        default:
            return state;
    }
}
