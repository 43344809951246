import React from 'react';
import { Drawer } from '@material-ui/core'
import { useStyles } from './styles'

const DrawerRight = ({ children, open }) => {
    const classes = useStyles();
    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
                paper: classes.rightDrawer,
            }}
        >
            {children}
        </Drawer>
    );
}
 
export default DrawerRight;