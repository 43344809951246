import React, { Fragment, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Grid,
  CircularProgress
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonAction from "../../components/buttonAction";
import { LocalPhone, Email, WhatsApp } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getFaqs } from "../../actions/faqActions";
import { getDataToken } from '../../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: "#141B6A",
      fontSize: 28,
      marginTop: 78,
      marginLeft: 20,
      marginBottom: 38,
      fontWeight: theme.typography.fontWeightLight,
      [theme.breakpoints.down("xs")]: {
        marginTop: 21,
        marginBottom: 19,
        fontWeight: 400,
        lineHeight: "24px",
        fontSize: 16,
        color: "#3B3B3B",
      },
    },
    root: {
      width: "100%",
      boxShadow: "none",
    },
    accordion: {
      backgroundColor: "transparent",
      marginBottom: 25,
    },
    summary: {
      border: "1px solid rgba(200, 180, 243, .6)",
      flexDirection: "row-reverse",
    },
    heading: {
      color: "#141B6A",
      marginLeft: 8,
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "24px",
      },
    },
    detail: {
      marginLeft: 50,
      paddingTop: 23,
      paddingBottom: 23,
      color: "#4B4B4B",
    },
  })
);

const FaqMain = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const faq = useSelector((state) => state.faq);
  const [faqsState, setFaqsState] = useState([]);
  const [havePermissionToSeeFaq, setHavePermissionToSeeFaq] = useState(false);

  const executeApi = async () => {
    await dispatch(getFaqs(token));
  }

  const handleGetFaqsByModule = () => {
    const faqs = faq?.data.docs.map(dataFaq => {
      const result = dataFaq.modules.map(module => module === 'insured' ? dataFaq : null).filter(value => value !== null)
      return result[0];
    }).filter(value => value !== undefined);

    setFaqsState(faqs)
  }

  useEffect(() => {
      executeApi();
      const dataToken = getDataToken(token)
      const result = dataToken.user.permissions?.Insured
      setHavePermissionToSeeFaq(result ? true : false)
  }, []);

  useEffect(() => {
    const dataFaq = faq.data?.docs;
    if(dataFaq){
      handleGetFaqsByModule()
    }
  }, [faq])

  const faqs = [
    {
      title: "¿Que es IConnectance?",
      detail:
        "iConnectance es una plataforma de venta de seguros multicanal que permite incrementar las ventas de una compañia de seguros",
    },
    {
      title: "¿Qué hace único a iConnectance?",
      detail:
        "iConnectance es una plataforma de venta de seguros multicanal que permite incrementar las ventas de una compañia de seguros",
    },
    {
      title: "¿Es seguro comprar por Internet?",
      detail:
        "iConnectance es una plataforma de venta de seguros multicanal que permite incrementar las ventas de una compañia de seguros",
    },
    {
      title: "¿Donde se guardan mis datos?",
      detail:
        "iConnectance es una plataforma de venta de seguros multicanal que permite incrementar las ventas de una compañia de seguros",
    },
    {
      title: "¿Es seguro comprar por Internet?",
      detail:
        "iConnectance es una plataforma de venta de seguros multicanal que permite incrementar las ventas de una compañia de seguros",
    },
    {
      title: "¿Es seguro comprar por Internet?",
      detail:
        "iConnectance es una plataforma de venta de seguros multicanal que permite incrementar las ventas de una compañia de seguros",
    },
  ];

  const contacts = [
    { title: "info@iconnectance.com", type: "email" },
    { title: "0800 5548-2639", type: "phone" },
    { title: "+54 11 6598 7435", type: "whatsapp" },
  ];

  return (
    <Container disableGutters={true}>
      <Typography variant="h2" className={classes.title}>
        Preguntas frecuentes
      </Typography>
      <div className={classes.root}>
        {havePermissionToSeeFaq && faqsState.length ? faqsState.map((faq, index) => (
          <Fragment key={index}>
            <Accordion elevation={0} className={classes.accordion}>
              <AccordionSummary
                IconButtonProps={{
                  edge: "start",
                }}
                className={classes.summary}
                expandIcon={<ExpandMoreIcon htmlColor={"#141B6A"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h2" className={classes.heading}>
                  {faq.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.detail}>
                <div dangerouslySetInnerHTML={{__html: faq.content}}></div>
              </AccordionDetails>
            </Accordion>
          </Fragment>
        )) : <CircularProgress color="primary"/> }
      </div>
      <Typography variant="h2" className={classes.title}>
        Contacto
      </Typography>
      <Grid container spacing={3}>
        {contacts.map((contact, index) => {
          const types = {
            email: <Email />,
            whatsapp: <WhatsApp />,
            phone: <LocalPhone />,
          };
          return (
            <Grid item key={index} xs={12} sm={6} md={4} className={classes.btnAction}>
              <ButtonAction icon={types[contact.type]} title={contact.title} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default FaqMain;
