import React from "react";
import {
  Snackbar,
  SnackbarContent,
  Slide,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { hideMessage } from "../../actions/notificationActions";

const useStyles = makeStyles((theme) => ({
  notification: {
    backgroundColor: theme.palette.variants && theme.palette.variants.variant2,
    color: "#333333",
  },
  icon: {
    fontSize: 20,
    color: "#333333",
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const MessageShow = () => {
  const classes = useStyles();
  const notifications = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideMessage());
  };

  return (
    <Snackbar
      open={notifications.message}
      onClose={handleClose}
      autoHideDuration={3000}
      TransitionComponent={SlideTransition}
    >
      <SnackbarContent
        className={classes.notification}
        message={
          <span className={classes.message}>
            <InfoOutlinedIcon
              className={`${classes.icon} ${classes.iconVariant}`}
            />
            <Typography>Ha recibido un nuevo mensaje</Typography>
          </span>
        }
        action={[
          <IconButton key="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default MessageShow;
