import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    sectionPassword: {
        paddingTop: 32,
        paddingBottom: 32
    },
    spaceInput: {
        paddingBottom: 20,
        width: '100%'
    },
    sectBtnSave: {
        paddingTop: 20,
        '& .btn-save': {
            backgroundColor: '#6C63FF'
        }
    },
}))