import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor:
            theme.palette.variants && theme.palette.variants.variant1,
        borderRadius: 18,
        padding: 10,
        color: theme.palette.secondary.main,
        width: '100%',
        display: 'flex',
        justifyContent: 'revert',
        '& span': {
            fontSize: 13
        },
        '&:hover': {
            backgroundColor:
                theme.palette.variants && theme.palette.variants.variant3,
            '& > span > span': {
                backgroundColor:
                    theme.palette.variants && theme.palette.variants.variant1
            }
        }
    },
    title: {
        fontSize: 13,
        paddingLeft: 10,
        width: '70%',
        textAlign: 'initial'
    },
    iconButton: {
        paddingLeft: 10,
        display: 'flex',
        position: 'absolute',
        right: 13,
        '& svg': {
            fontSize: 14
        }
    },
    icon: {
        backgroundColor: theme.palette.variants.variant3,
        padding: '9px 11px',
        borderRadius: 30,
        display: 'flex'
    }
}));
