import {
    GET_LEAD_COMMENT_PENDING,
    GET_LEAD_COMMENT_FULFILLED,
    GET_LEAD_COMMEN_REJECTED
} from '../actions/types';

const initialState = {
    data: {},
    loading: false,
    error:false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LEAD_COMMENT_PENDING:
            return {
                ...state,
                data: {...state.data},
                loading:true,
                error:false
            };
        case GET_LEAD_COMMENT_FULFILLED:
            return {
                ...state,               
                data: {
                    ...state.data,
                    [action.payload[0]?.leadId]: action.payload
                },
                loading: false,
                error: false
            };
        case GET_LEAD_COMMEN_REJECTED:
            return {
                ...state,
                data: {...state.data},
                loading:false,
                error:true
            };
        default:
            return state;
    }
}
