import { getClaimCausesAPI } from '../sevices/claimCausesService';
import { GET_CLAIM_CAUSES_REJECTED, GET_CLAIM_CAUSES_PENDING } from './types';

export const getClaimCausesPending = () => {
    return {
        type: GET_CLAIM_CAUSES_PENDING
    };
};

export const getClaimCauses = (token, prefixCode) => async (dispatch) => {
    await dispatch(getClaimCausesAPI(token, prefixCode));
};

export const getClaimCausesRejected = () => {
    return {
        type: GET_CLAIM_CAUSES_REJECTED
    };
};
