import React, { useEffect } from 'react';
import clsx from 'clsx';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useSelector } from 'react-redux';

const IconSinister = ({ className }) => {
    const {
        userConfig: {
            data: { theme }
        }
    } = useSelector((state) => state);

    useEffect(() => {
        console.log(theme);
    }, [theme]);

    return (
        <SvgIcon className={clsx(className)} viewBox="0 0 70 70" fill="none">
            <path
                d="M49.355 0H37.051c-3.752 0-7.226 1.087-10.254 2.853-6.1 3.558-10.254 10.236-10.254 17.791 0 7.556 4.154 14.097 10.254 17.655 3.028 1.767 6.502 2.853 10.254 2.853h12.304c11.308 0 20.645-9.2 20.645-20.508C70 9.338 60.663 0 49.355 0Z"
                fill={theme?.secondary?.variant4 || '#C8B4F3'}
            />
            <path
                d="M49.355 41.152c11.308 0 20.645-9.2 20.645-20.508C70 9.338 60.663 0 49.355 0h-6.152v41.152h6.152Z"
                fill={theme?.secondary?.variant4 || '#B89EEF'}
            />
            <path
                d="M32.95 20.645H20.644C9.337 20.645 0 29.845 0 41.151 0 52.46 9.337 61.66 20.645 61.66h.782l3.535 7.206a2.052 2.052 0 0 0 3.67 0l3.534-7.206h.783c11.307 0 20.508-9.2 20.508-20.508 0-11.307-9.2-20.508-20.508-20.508Z"
                fill={theme?.primary?.main || '#141B6A'}
                fillOpacity={0.64}
            />
            <path
                d="m28.631 68.866 3.535-7.206h.783c11.307 0 20.508-9.2 20.508-20.508 0-11.307-9.2-20.508-20.508-20.508h-6.152V70c.777 0 1.486-.439 1.834-1.134Z"
                fill={theme?.primary?.main || '#141B6A'}
                fillOpacity={0.8}
            />
            <path
                d="M26.797 28.848c-6.785 0-12.305 5.52-12.305 12.304 0 6.786 5.52 12.305 12.305 12.305s12.305-5.52 12.305-12.305-5.52-12.304-12.305-12.304Zm-8.203 12.304c0-4.524 3.679-8.203 8.203-8.203 1.517 0 2.92.442 4.14 1.162l-4.14 4.142-7.041 7.04c-.72-1.22-1.162-2.624-1.162-4.14Zm8.203 8.203c-1.517 0-2.921-.441-4.141-1.161l4.14-4.142 7.042-7.04c.72 1.22 1.162 2.624 1.162 4.14 0 4.524-3.68 8.203-8.203 8.203Z"
                fill="#fff"
            />
            <path
                d="M39.102 41.152c0-6.785-5.52-12.304-12.305-12.304v4.101c1.517 0 2.92.442 4.14 1.162l-4.14 4.142v5.8l7.041-7.042c.72 1.22 1.162 2.625 1.162 4.141 0 4.524-3.68 8.203-8.203 8.203v4.102c6.785 0 12.305-5.52 12.305-12.305Z"
                fill="#fff"
            />
        </SvgIcon>
    );
};

export default IconSinister;
