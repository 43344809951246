import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useHasDocuments() {
    const agentBankInfo = useSelector((state) => state.agentBankInfo);
    const agentDocumentInfo = useSelector((state) => state.agentDocumentInfo);

    return useMemo(() => agentBankInfo.data && agentDocumentInfo.data ? true : false, [agentBankInfo, agentDocumentInfo]);
}

export default useHasDocuments;