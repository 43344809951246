import { createAsyncAction } from 'redux-promise-middleware-actions';
import {
    insuredApi,
    validateJSONResponse,
    exceptionCodeResponse
} from '../utils/urls';
import {
    getClaimCausesPending,
    getClaimCausesRejected
} from '../actions/claimCausesAction';

export const getClaimCausesAPI = createAsyncAction(
    'GET_CLAIM_CAUSES',
    async (token, prefixCode) => {
        const res = await fetch(`${insuredApi}v1/insured/list/claimCauses`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ prefixCode })
        })
            .then((res) => {
                getClaimCausesPending();
                return validateJSONResponse(res);
            })
            .catch((err) => {
                getClaimCausesRejected();
                throw exceptionCodeResponse(err);
            });
        return res;
    }
);
