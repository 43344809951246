import {
    GET_REQUEST_WORKFLOW_GROUP,
    GET_REQUEST_WORKFLOW_GROUP_PENDING,
    GET_REQUEST_WORKFLOW_GROUP_FULFILLED,
    GET_REQUEST_WORKFLOW_GROUP_REJECTED,
} from '../actions/types';

  const initialState = {
    data: [],
    loading: false,
    error: false
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_REQUEST_WORKFLOW_GROUP_PENDING:
        return {
          ...state,
          loading: true,
          error: false
        };
      case GET_REQUEST_WORKFLOW_GROUP_FULFILLED:
        return {
          ...state,
          data: [...state.data, ...action.payload],
          loading: false,
          error: false
      };
      case GET_REQUEST_WORKFLOW_GROUP_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true
        };
      case GET_REQUEST_WORKFLOW_GROUP:
        return {
          ...state,
          selected: action.payload
        };
      default:
        return state;
    }
  }