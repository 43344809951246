import { createAsyncAction } from "redux-promise-middleware-actions";
import { quotationUrlApi } from "../utils/urls";
import { GET_ALL_ACTIONS } from "../actions/types";

export const getAllActionsAPI = createAsyncAction(
  GET_ALL_ACTIONS,
  async (token, filter) => {
    const querystring = new URLSearchParams(filter);
    const params = querystring.toString();

    const headers = { Authorization: `Bearer ${token}` };
    const res = await fetch(
      `${quotationUrlApi}actions${params ? `?${params}` : ""}`,
      {
        headers,
      }
    );
    return res.json();
  }
);