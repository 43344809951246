import {
    SHOW_SUCCESS,
    SHOW_WARNING,
    SHOW_ERROR,
    HIDE_SUCCESS,
    HIDE_WARNING,
    HIDE_ERROR,
    SHOW_MESSAGE,
    HIDE_MESSAGE
} from '../actions/types';

const initialState = {
    success: false,
    warning: false,
    error: false,
    message: false
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SUCCESS:
      return {
        success: action.payload,
        msg: action.msg
      };
    case SHOW_WARNING:
      return {
        warning: action.payload
      };
    case SHOW_ERROR:
      return {
        error: action.payload
      };
    case SHOW_MESSAGE:
      return {
        message: action.payload
      };
    case HIDE_SUCCESS:
      return {
        success: false
      };
    case HIDE_WARNING:
      return {
        warning: false
      };
    case HIDE_ERROR:
        return {
          error: false
        };
    case HIDE_MESSAGE:
      return {
        message: false
      };
    default:
      return state;
  }
};

export default notificationReducer;
