import React, { useEffect, useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { renderSelectField, renderDateField } from '../form/renderInputsForm';
import SearchProvinceComponent from '../province/SearchProvinceComponent';
import moment from 'moment';
import { useStyles } from './styles';

const validate = (values) => {
    const errors = {};
    const requiredFields = [
        'registerDate',
        'sinisterCause',
        'cover',
        'descriptionSinister'
    ];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

function DetailsGeneralForm({ handleSubmit, handleStep, coberturas }) {
    const dispatch = useDispatch();
    const [registerDate, setRegisterDate] = useState();
    const classes = useStyles();
    const { claimCauses } = useSelector((state) => state);
    const [causes, setCauses] = useState([]);

    useEffect(() => {
        const today = moment().format('MM/DD/YYYY');
        dispatch(change('detailgeneral', 'registerDate', today));
        setRegisterDate(today);
    }, []);

    useEffect(() => {
        setCauses(claimCauses?.data?.data || []);
    }, [claimCauses?.data?.data]);

    const handleSubmits = (values) => {
        handleStep({
            step: 'policy'
        });
    };

    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Grid container direction="row">
                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="registerDate"
                        label="Fecha de registro"
                        value={registerDate}
                        dateValue={registerDate}
                        component={renderDateField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <SearchProvinceComponent
                    defaultvalues={{
                        country: '1',
                        province: '1'
                    }}
                    formName="detailgeneral"
                />
                <Grid item xs={6} md={6} style={{ padding: 8 }}>
                    <Field
                        name="sinisterCause"
                        label="Causa del siniestro"
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                    >
                        {causes &&
                            causes.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.ClaimCauseCode}
                                >
                                    {item.Description}
                                </MenuItem>
                            ))}
                    </Field>
                </Grid>
                <Grid item xs={6} md={6} style={{ padding: 8 }}>
                    <Field
                        name="cover"
                        label="Cobertura"
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                    >
                        {coberturas &&
                            coberturas.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                    </Field>
                </Grid>
                <Grid item xs={12} md={12} style={{ padding: 8 }}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Descripcion detallada de como ocurrio"
                        name="descriptionSinister"
                        multiline
                        rows={4}
                        defaultValue=""
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            Siguiente
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

DetailsGeneralForm.propTypes = {};

export default reduxForm({ form: 'detailgeneral', validate })(
    DetailsGeneralForm
);
