export const primary = {
  main: "#4C1DB7", // main BO
  dark: "#6716F5", // variant1 BO
};

export const secondary = {
  main: "#141B6A", // main BO
  dark: "#00A0F8", // varian1 BO
};

export const background = {
  main: "#F4F4FA", //primary
  secondary: "#E4DAF9", //secondary
};

export const variants = {
  variant1: "#E4DAF9", //variant2 BO
  variant2: "#D8D0F6", //variant3 BO
  variant3: "#C8B4F3", //variant4 BO
  variant4: "#AF8AFC", //variant5 BO
};

export const neutral = {
  white: "#FFFFFF",
  grey1: "#F2F2F2",
  grey2: "#E0E0E0",
  grey3: "#C4C4C4",
  grey4: "#828282",
  grey5: "#4B4B4B",
};

export const success = {
  light: "#E2F5EA",
  main: "#6FCF97",
  dark: "#219653",
};

export const error = {
  light: "#FBDDDD",
  main: "#EB5757",
  dark: "#B53D3D",
};

export const warning = {
  light: "#FCEBDB",
  main: "#F2994A",
  dark: "#D78741",
  w2Main: "#FFF4D2",
  w2Dark: "#F2C94C",
};
