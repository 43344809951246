export const validate = (values) => {
    const errors = {};
    const requireFields = [
        'clientName',
        'phoneNumber',
        'license',
        'endLicense',
        'descriptionSinister',
        'descriptionClaim',
        'clientName',
        'cardNumber',
        'observations'
    ];

    requireFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });

    if (values.license && values.license.length > 8) {
        errors.license = 'Maximo permitido 8 caracteres';
    }

    if (values.descriptionSinister && values.descriptionSinister.length > 500) {
        errors.descriptionSinister = 'Maximo permitido 500 caracteres';
    }

    if (values.descriptionClaim && values.descriptionClaim.length > 500) {
        errors.descriptionClaim = 'Maximo permitido 500 caracteres';
    }

    if (values.location && values.location.length > 50) {
        errors.location = 'Maximo permitido 50 caracteres';
    }

    if (values.brand && values.brand.length > 15) {
        errors.brand = 'Maximo permitido 15 caracteres';
    }

    if (values.model && values.model.length > 15) {
        errors.model = 'Maximo permitido 15 caracteres';
    }

    if (values.patente && values.patente.length > 9) {
        errors.patente = 'Maximo permitido 9 caracteres';
    }

    if (values.observations && values.observations.length > 100) {
        errors.observations = 'Maximo permitido 100 caracteres';
    }

    if (values.cardNumber && values.cardNumber.length > 16) {
        errors.cardNumber = 'Maximo permitido 16 caracteres';
    }

    if (values.observations && values.observations.length > 16) {
        errors.cardNumber = 'Maximo permitido 100 caracteres';
    }

    return errors;
};
