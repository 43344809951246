import {
  GET_REASONS_PENDING,
  GET_REASONS_FULFILLED,
  GET_REASONS_ERROR,
  GENERATE_CANCEL_PENDING,
  GENERATE_CANCEL_FULFILLED,
  GENERATE_CANCEL_ERROR,
} from "../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export const reasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REASONS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_REASONS_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_REASONS_ERROR:
      return {
        ...state,
        data: [],
        error: true,
      };
    default:
      return state;
  }
};

export const generateCancelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_CANCEL_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GENERATE_CANCEL_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GENERATE_CANCEL_ERROR:
      return {
        ...state,
        data: [],
        error: true,
      };
    default:
      return state;
  }
};
