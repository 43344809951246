import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button, Grid, TextField, CircularProgress } from '@material-ui/core';
import {
    renderTextField,
    UploadFileAsync,
    renderSelectField,
    renderDateField
} from '../form/renderInputsForm';
import { RenderSearchSelectFieldSimple } from '../quoter/quoteInputs/index';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';

import { getFilesSinister } from '../../actions/filesSinisterActions';

const useStylesForm = makeStyles((theme) => ({
    sectFormComment: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '15px !important'
    },
    fontSizeLabel: {
        '& label': {
            fontSize: 14
        }
    }
}));
function DetailsSupportForm({ handleStep }) {
    const classesForm = useStylesForm();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { filesSinister } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getFilesSinister());
    }, []);

    const formatFilesSinister = useMemo(() => {
        return filesSinister.data?.data?.map((x) => x);
    }, [filesSinister]);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleStep({
            step: 'support'
        });
    };

    const requestID = '';

    const handleChange = async (e, name) => {};

    // const uploadSettings = {
    //   requestID: requestId ? requestId : "defaultRequestIDValue",
    //   insuredName: decoded.user._id,
    //   repositoryCode: "10", //bucket s3
    // };

    // const findFile = (requestTypeFileName) => {
    //   let result = undefined;

    //   const requestTypeFileNameFormated = requestTypeFileName.replace(/ /g, "-");

    //   if (requestFilesSelect) {
    //     const file = requestFilesSelect.find((item) =>
    //       item.name.includes(requestTypeFileNameFormated)
    //     );

    //     if (file) {
    //       result = file.url;
    //     }
    //   }

    //   return result;
    // };

    return (
        <form onSubmit={handleSubmit}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
            >
                {!formatFilesSinister ? (
                    <Grid item xs={12} md={12}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    formatFilesSinister.map((typeFile) => (
                        <Grid item xs={8} md={6} style={{ padding: 8 }}>
                            <Field
                                key={typeFile.codigoRamoTecnico}
                                name="addSupport"
                                label={typeFile.descripcionArchivo}
                                custom={{ tabIndex: 1 }}
                                component={UploadFileAsync}
                                handle={handleChange}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                name="observations"
                                label="Observacion"
                                multiline
                                rows={2}
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                    ))
                )}

                <Grid item sx={6} md={6} style={{ padding: 10 }}></Grid>
                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            Siguiente
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

DetailsSupportForm.propTypes = {};

export default reduxForm({ form: 'support' })(DetailsSupportForm);
