import {
  GET_DOCUMENTS_LIST_PENDING,
  GET_DOCUMENTS_LIST_FULFILLED,
  GET_DOCUMENTS_LIST_REJECTED,
} from "../actions/types";

const initialState = {
    data: [],
    loading: false,
    error: false,
  };
  
  export const listDocumentsTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DOCUMENTS_LIST_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_DOCUMENTS_LIST_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_DOCUMENTS_LIST_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };