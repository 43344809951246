import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    cardGraph: {
        width: '100% !important',
        backgroundColor: '#fff !important',
        padding: '14px 15px !important',
        borderRadius: '15px !important',
        position:'relative !important'
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    paddingBox: {
        padding: '13rem 0'
    },
    instructionsContainer:{
        border: 'solid 1px',
        position: 'absolute',
        width: '100%',
        backgroundColor:'white',
        left:0,
        paddingLeft:'15px'
    },
    line: {
        border: '1px solid ' + theme.palette.secondary.main || '#AF8AFC',
        transform: 'rotate(90deg)',
        width: '3rem',
        marginTop: 22,
        marginLeft: 26,
    },
    lineActive: {
        border: `1px solid red`
    },
    circle: {
        marginLeft: '1.93rem',
        width: '42px !important',
        height: '42px !important',
        borderRadius:'40px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#F4F4FA',
        '@media (max-width: 960px)': { // eslint-disable-line no-useless-computed-key
            marginLeft: '0rem',
        }
    },
    circleActive: {
        marginLeft: '1.45rem !important',
        width: '29px !important',
        height: '29px !important',
        marginTop: '2px !important'
    },
    marginTop: {
        marginTop: 20
    },
    outLine: {
        margin: 0,
        paddingLeft: 5,
        listStyle: 'outside none none',
        top: 35
    },
    titleStep:{
        position: 'absolute',
        left:'calc(3rem + 43px)',
        top:'0px',
        fontSize: '36px',
        color:theme.palette.primary.main || '#4C1DB7',
        '@media (max-width: 960px)': { // eslint-disable-line no-useless-computed-key
            left: 'calc(1rem + 43px)',
        }
    },
    liContainer:{
        position:'relative',
        listStyle: 'none',
    },
    stepActionsContainer:{
        marginTop:"50px",
        marginBottom:"40px",
        width: 'calc(100% - calc(3rem + 123px))',
        marginLeft: 'calc(3rem + 43px)',
        '@media (max-width: 960px)': { // eslint-disable-line no-useless-computed-key
            marginLeft: 'calc(1rem + 43px)',
            width: 'calc(100% - calc(1rem + 43px))',
        }
    },
    stepActionsContainerDisabled:{
        marginTop:"50px",
        marginBottom:"40px",
        width: 'calc(100% - calc(3rem + 123px))',
        marginLeft: 'calc(3rem + 43px)',
        opacity:'0.2',
        pointerEvents:'none',
        '@media (max-width: 960px)': { // eslint-disable-line no-useless-computed-key
            marginLeft: 'calc(1rem + 43px)',
            width: 'calc(100% - calc(1rem + 43px))',
        }
    },
    stepActionsContainerDisabledShow:{
        marginTop:"50px",
        marginBottom:"40px",
        width: 'calc(100% - calc(3rem + 123px))',
        marginLeft: 'calc(3rem + 43px)',
        opacity:'1',
        pointerEvents:'none',
        '@media (max-width: 960px)': { // eslint-disable-line no-useless-computed-key
            marginLeft: 'calc(1rem + 43px)',
            width: 'calc(100% - calc(1rem + 43px))',
        }
    },
    line:{
        width: '2px',
        backgroundColor: theme.palette.secondary.main || '#AF8AFC',
        border:'none',
        position: 'absolute',
        height: '100% !important',
        left: '50px',
        top:'33px',
        '@media (max-width: 960px)': { // eslint-disable-line no-useless-computed-key
            left: '17.5px',
        }
    },
    rowInitialData:{
        backgroundColor: '#F4F4FA',
        height: '61px',
        display:'flex',
        alignItems:'center',
        paddingLeft: '19px',
        fontWeight: 'bold',
        borderBottom: 'solid 1px #4C1DB7',
        "& svg":{
            color:theme.palette.secondary.main || '#4C1DB7'
        }
    },
    violetaColorText:{
        color:theme.palette.secondary.main || '#4C1DB7'
    },
    buttonNextStep:{
        width: "186px",
        height: "44px",
        fontSize: "14px",
        backgroundColor: theme.palette.primary.main || '#4C1DB7' + " !important",
        color:"white !important",
        fontWeight: "bold !important",
        marginTop: "28px !important",
        position: "relative",
        right: "0px",
        '&:hover':{
            backgroundColor: theme.palette.primary.main || '#4C1DB7' + " !important",
        }
    },
    buttonContainer:{
        textAlign: "right"
    },
    styledLabel:{
        fontSize: '0.8rem',
        position: 'absolute',
        top: '2px',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)'

    },
    toggleDebitoButtons:{
        backgroundColor: '#F4F4FA !important',
        color: theme.palette.secondary.main || '#4C1DB7'+ ' !important',
        width: '109px',
        height: '36px',
        borderRadius: '20px !important',
        fontWeight: 'bold !important',
        margin:'7px !important'
    },
    containerStepImage:{
        position:'relative',
        textAlign: 'center',
        width: '100%',
        '& svg':{
            '@media (max-width: 960px)': { // eslint-disable-line no-useless-computed-key
                width:'100% !important'
            }
        }
    },
    loadingContainer:{
        position:'relative',
        textAlign: 'center',
        width: '100%',
        position: 'absolute',
        bottom:'30%'
    },
    buttonAddDependent:{
        border: 'dashed !important',
        borderColor: '#C4C4C4 !important',
        borderRadius: '18px !important',
        borderWidth: '1px !important',
        padding: '10px !important',
        color:theme.palette.secondary.main || '#4C1DB7'+ ' !important'
    },
    svgIcon: {
        color: theme.palette.primary.main,
        width: 'auto',
        height: 'auto'
    },
    itemContainer:{
        borderColor: theme.palette.primary.main || '#4C1DB7' + " !important",
        border:'solid',
        borderWidth:'1px',
        borderRadius:'20px',
        marginTop:'30px',
        padding:'10px'
    },
    drawerEl: {
        width: 600,
        backgroundColor: '#F4F4FA',
        borderTopLeftRadius: 10,
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        }
      },
      headerDrawer: {
        backgroundColor: '#4c1db7',
        fontWeight: 500,
        fontSize: '18px',
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '77px',
      },
      titleContainer: {
        height: '77px',
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
      },
}));