import {
  BLOCK_NETWORK_FILTER,
  GET_MEDICAL_NETWORK_ATTENTION_POINT_TYPES,
  GET_SUPPLIER_MEDICAL_NETWORK_SISE,
  SELECT_SERVICE,
  SELECT_SUPPLIER,
} from "../actions/types";

const initialState = {
  data: [],
  supplier: "",
  service: "",
  loading: false,
  block: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_SUPPLIER_MEDICAL_NETWORK_SISE}_PENDING`:
    case `${GET_MEDICAL_NETWORK_ATTENTION_POINT_TYPES}_PENDING`:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case `${GET_SUPPLIER_MEDICAL_NETWORK_SISE}_FULFILLED`:
    case `${GET_MEDICAL_NETWORK_ATTENTION_POINT_TYPES}_FULFILLED`:
      return {
        ...state,
        data:
          state.data.length > 0
            ? [...state.data, ...action.payload.response]
            : action.payload.response,
        loading: false,
        error: false,
      };
    case `${GET_SUPPLIER_MEDICAL_NETWORK_SISE}_REJECTED`:
    case `${GET_MEDICAL_NETWORK_ATTENTION_POINT_TYPES}_REJECTED`:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case `${SELECT_SUPPLIER}`: {
      const name = action.payload.name;

      if (!name.length) {
        return {
          ...state,
          supplier: "",
        };
      }
      return {
        ...state,
        supplier: name,
      };
    }
    case `${SELECT_SERVICE}`:
      const service = action.payload;
      return {
        ...state,
        service: service,
      };
    case `${BLOCK_NETWORK_FILTER}`:
      return {
        ...state,
        block: action.payload,
      };
    default:
      return state;
  }
}
