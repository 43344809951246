import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const StyledExpansionPanel = withStyles({
  root: {
    boxShadow: '0 0 0 0'
  }
})(Accordion);

const StyledExpansionPanelSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    padding: 0,
    '&:hover': {
      cursor: 'default !important'
    }
  },
  focused: {
    backgroundColor: '#fff !important'
  },
  content: {
    margin: 0
  }
})(AccordionSummary);

const ControlledExpansionPanel = ({ children, title }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledExpansionPanel expanded={expanded}>
      <StyledExpansionPanelSummary expandIcon={<ExpandMore />} IconButtonProps={{ onClick: () => { handleExpand(); } }}>
        <Typography>{title}</Typography>
      </StyledExpansionPanelSummary>
      {children}
    </StyledExpansionPanel>
  );
}

const NotificationEditForm = ({ events, handleUpdate }) => {
  const [values, setValues] = useState({});
  const config = useSelector(state => state.userConfig.data);

  useEffect(() => {
    if (events.length > 0) {
      let mappedEvents = {};

      events.forEach((event, index) => {
        mappedEvents[`event${index}`] = {
          frequency: event.frequency,
          notificationType: event.notificationType,
          active: event.active
        };
      });

      setValues(mappedEvents);
    }
  }, [events]);

  const frequencies = [
    { label: 'Inmediata', value: 'push' },
    { label: 'Por Hora', value: '1 hour' },
    { label: 'Diaria', value: '1 day' },
    { label: 'Semanal', value: '1 week' },
    { label: 'Mensual', value: '1 month' }
  ];

  const types = [
    { label: 'Notificación Push', value: 'Push' },
    { label: 'eMail', value: 'Email' },
    { label: 'SMS', value: 'SMS' },
    { label: 'WhatsApp', value: 'WhatsApp' },
  ];

  const handleValueChange = (evt) => {
    const [parent, child] = evt.target.name.split('.');
    setValues({
      ...values,
      [parent]: {
        ...values[parent],
        [child]: evt.target.checked !== undefined ? evt.target.checked : evt.target.value
      }
    });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const updatedEvents = events.map((event, index) => {
      return {
        ...event,
        ...values[`event${index}`]
      };
    });

    console.log(updatedEvents);
    await handleUpdate(updatedEvents);
    setValues({});
  }

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      {Object.keys(values).length > 0 && events.map((event, index) => (
        <ControlledExpansionPanel
          key={`group_${index}`}
          title={event.description}
        >
          <AccordionDetails>
            <Grid alignItems="stretch" container direction="column">
              <Grid item style={{ marginTop: '1rem' }}>
                <TextField
                  select
                  className="formInput"
                  label="Frecuencia"
                  variant="outlined"
                  size="small"
                  name={`event${index}.frequency`}
                  fullWidth
                  onChange={handleValueChange}
                  value={values[`event${index}`].frequency}
                >
                  {
                    frequencies.map((freq, i) => {
                      return (
                        <MenuItem key={`freq_index_${i}`} value={freq.value}>{freq.label}</MenuItem>
                      )
                    })
                  }
                </TextField>
              </Grid>
              <Grid item style={{ marginTop: '1rem' }}>
                <TextField
                  select
                  className="formInput"
                  label="Canal"
                  variant="outlined"
                  size="small"
                  name={`event${index}.notificationType`}
                  fullWidth
                  onChange={handleValueChange}
                  value={values[`event${index}`].notificationType}
                >
                  {types.map((t, index) => {
                    return (
                      <MenuItem key={`type_${index}`} value={t.value.toLowerCase()}>
                        {t.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={`event${index}.active`}
                      onChange={handleValueChange}
                      checked={values[`event${index}`].active}
                    />
                  }
                  label="Activo"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </ControlledExpansionPanel>
      ))}
      <div style={{ marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{
            borderRadius: "12px !important",
            boxShadow: "none",
            width: "100%",
            backgroundColor: config.theme && config.theme.primary.main,
            color: "#FFF",
          }}
          type="submit"
        >
          Guardar
        </Button>
      </div>
      {/* <FieldArray name="events" component={renderNotificationGroup} /> */}
    </form>
  );
};

export default NotificationEditForm;
