import {
  GET_AWARDS,
  GET_AWARDS_FULFILLED,
  GET_AWARDS_REJECTED,
  GET_AWARDS_PENDING
} from "../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AWARDS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_AWARDS_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_AWARDS_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case GET_AWARDS:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
}
