import {
    GET_BALANCE_INQUIRY_PENDING,
    GET_BALANCE_INQUIRY_FULFILLED,
    GET_BALANCE_INQUIRY_REJECTED,
    GET_BALANCE_INQUIRY,

    SHOW_DRAWER_COMMISSION_PAYMENT,
    SHOW_DRAWER_PROOF_OF_PAYMENT,
    CURRENCY_SELECTED_BALANCE_INQUIRY,
  } from '../actions/types';

  const initialState = {
    data: [],
    showDrawerCommissionPayment: false,
    showDrawerProofOfPayment: false,
    currencySelectedInBalanceInquiry: '',
    currencySelectedInDrawerCommissionPayment: '',
    loading: false,
    error: false,
    selected: null
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_BALANCE_INQUIRY_PENDING:
        return {
          ...state,
          loading: true,
          error: false
        };
      case GET_BALANCE_INQUIRY_FULFILLED:
        // TODO: habia un error en la linea 35? preguntar a gregory para ver si el cambio es viable.
        //       antes =>  data: [...state.data, ...action.payload.data]
        const commissions = [];

        for (const i in action.payload.commissions) {
          const el = action.payload.commissions[i];

          if (state.data.length > 0) {
            state.data[0].commissions[i]._id !== el._id && commissions.push(el);
          }
          commissions.push(el);
        }

        return {
          ...state,
          data: [{ ...action.payload, commissions }],
          loading: false,
      };
      case GET_BALANCE_INQUIRY_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true
        };
      case GET_BALANCE_INQUIRY:
        return {
          ...state,
          selected: action.payload
        };
      case SHOW_DRAWER_COMMISSION_PAYMENT:
        return {
          ...state,
          showDrawerCommissionPayment: !state.showDrawerCommissionPayment
        };
      case SHOW_DRAWER_PROOF_OF_PAYMENT:
        return {
          ...state,
          showDrawerProofOfPayment: !state.showDrawerProofOfPayment
        };
      case CURRENCY_SELECTED_BALANCE_INQUIRY:
        return {
          ...state,
          currencySelectedInBalanceInquiry: action.payload,
        };
      default:
        return state;
    }
  }

