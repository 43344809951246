import { createAsyncAction } from 'redux-promise-middleware-actions';
import { urlBaseApi, validateJSONResponse, exceptionCodeResponse } from '../utils/urls';
import { GET_ACCOUNT_TYPES } from '../actions/types'

export const getAccountTypes = createAsyncAction(
    GET_ACCOUNT_TYPES,
    async (token) => {
        const options = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };
        const res = await fetch(`${urlBaseApi}/accountTypes`, options)
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);