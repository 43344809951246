import axios from "axios";
import jwt_decode from "jwt-decode";
import { createAsyncAction } from "redux-promise-middleware-actions";
import {
  authUrlApi,
  validateResponse,
  exceptionCodeResponse,
} from "../utils/urls";
import { headers } from "../utils/index";
import { GET_USER_BY_ID } from "../actions/types";

export const loginUserAPI = createAsyncAction("POST_LOGIN", async (bodyReq) => {
  const headers = { "Content-Type": "application/json" };
  const body = JSON.stringify(bodyReq);
  const res = await fetch(`${authUrlApi}/login`, {
    method: "POST",
    headers,
    body,
  })
    .then((response) => validateResponse(response))
    .catch((error) => {
      throw exceptionCodeResponse(error);
    });
  return res;
});

export const getDataUserLoggedIn = () => {
  const obj = JSON.parse(
    localStorage.getItem("persist:root_cotizador_backoffice")
  );
  const auth = JSON.parse(obj.auth);
  const userData = jwt_decode(auth.token);

  const headers = { Authorization: `Bearer ${auth.token}` };
  const response = axios.get(
    `${authUrlApi}/usersInfo/${userData.user._id}`,
    { headers }
  );
  return response;
};

export const getUserLogged = createAsyncAction(GET_USER_BY_ID, async () => {
  const obj = JSON.parse(
    localStorage.getItem("persist:root_cotizador_backoffice")
  );
  const auth = JSON.parse(obj.auth);
  const userData = jwt_decode(auth.token);

  const headers = { Authorization: `Bearer ${auth.token}` };
  const response = axios.get(
    `${authUrlApi}/users/${userData.user._id}`,
    { headers }
  );
  return response;
});

export const updateDataUserInfo = async (data) => {
  try {
    const response = await axios.put(
      `${authUrlApi}/usersInfo/update`,
      data,
      headers()
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
