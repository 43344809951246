import React, { Fragment, useState } from 'react';
import { navigate } from '@reach/router';
import {
    Typography,
    IconButton,
    ListItem,
    ListItemText,
    List,
    ListSubheader,
    Divider
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import DrawerDetailNotification from '../../../notifications/drawers/drawerDetailNotification';
import { useStyles } from './styles';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { readNotification } from '../../../../actions/userEventsActions';

const Notification = ({ goBack, open }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedMessage, setSelectedMessage] = useState({});
    const [openDrawer, setOpenDrawer] = useState(false);
    const auth = useSelector((state) => state.auth);
    const userEvent = useSelector((state) => state.userEvents);
    const notificationsList = userEvent.notifications.sort(
        (a, b) => new Date(b?.sentAt) - new Date(a?.sentAt)
    );

    const handleReadMessage = async (noti) => {
        setSelectedMessage(noti);
        // setOpenNotiReader(true);
        handleToggleDrawer(true);
    };

    const handleRead = async (noti) => {
        if (!noti.read) {
            await dispatch(readNotification(auth.token, noti._id));
        }
    };

    const handleToggleDrawer = (opt) => {
        setOpenDrawer(opt);
    };

    const showMoreNotif = () => {
        return (
            <>
                <ListItem
                    alignItems="flex-start"
                    onClick={() => navigate(`/notifications`)}
                    classes={{
                        root: classes.rootNotif
                    }}
                >
                    <ListItemText
                        primary={
                            <strong>
                                <p
                                    style={{
                                        color: '#333',
                                        textAlign: 'center',
                                        margin: 0
                                    }}
                                >
                                    Ver mas
                                </p>
                            </strong>
                        }
                    />
                </ListItem>
            </>
        );
    };

    const generateList = () => {
        return (
            <List
                classes={{
                    root: classes.rootSubheaderWrapper
                }}
                subheader={
                    <>
                        <ListSubheader
                            classes={{
                                root: classes.rootSubheader
                            }}
                            disableSticky={true}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px 0px'
                                }}
                            >
                                <Typography color="primary">
                                    <strong>Notificaciones</strong>
                                </Typography>
                                <IconButton
                                    color="inherit"
                                    className={classes.noPadding}
                                    onClick={goBack}
                                >
                                    <Close />
                                </IconButton>
                            </div>
                        </ListSubheader>
                        <Divider variant="middle" component="li" />
                    </>
                }
            >
                {notificationsList.length > 0 &&
                    notificationsList
                        .filter((_, i) => i < 10)
                        .map((notification, index) => {
                            const _date = moment(notification?.sentAt)
                                .format('hh:mm   DD/MM/YYYY')
                                .split('   ');
                            return (
                                <Fragment key={'notif' + index}>
                                    <ListItem
                                        onClick={() =>
                                            handleReadMessage(notification)
                                        }
                                        alignItems="flex-start"
                                        classes={{
                                            root: !notification?.read
                                                ? classes.rootNotifUnread
                                                : classes.rootNotif
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <strong
                                                        style={{
                                                            color: '#333'
                                                        }}
                                                    >
                                                        {
                                                            notification
                                                                ?.message?.title
                                                        }
                                                    </strong>
                                                }
                                                secondary={
                                                    <Fragment>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className={
                                                                classes.inline
                                                            }
                                                            color="textPrimary"
                                                        >
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: notification
                                                                        ?.message
                                                                        ?.content
                                                                }}
                                                            />
                                                        </Typography>
                                                    </Fragment>
                                                }
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    color: '#333'
                                                }}
                                            >
                                                <small
                                                    style={{ color: '#D8D8D8' }}
                                                >
                                                    <i>{`${_date[1]}   `}</i>
                                                </small>
                                                <small
                                                    style={{ marginLeft: 5 }}
                                                >
                                                    <i>{_date[0]}</i>
                                                </small>
                                            </div>
                                        </div>
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                </Fragment>
                            );
                        })}
                {notificationsList.length > 0 && showMoreNotif()}
                {notificationsList.length === 0 && (
                    <ListItem>
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <ListItemText
                                secondary={
                                    <Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            No posee notificaciones actualmente
                                        </Typography>
                                    </Fragment>
                                }
                            />
                        </div>
                    </ListItem>
                )}
            </List>
        );
    };
    return (
        <Fragment>
            <Drawer
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper
                }}
                onClose={goBack}
            >
                {generateList()}
            </Drawer>
            <DrawerDetailNotification
                open={openDrawer}
                close={() => handleToggleDrawer(false)}
                notification={selectedMessage}
                handleRead={handleRead}
            />
        </Fragment>
    );
};

export default Notification;
