import React from "react";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Loader = ({ isLoading, solid }) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: solid ? 'rgb(255, 255, 255)' : 'rgba(0, 0, 0, 0.5)'
    },
  }));
  
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress disableShrink />
    </Backdrop>
  );
};

export default Loader;
