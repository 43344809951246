import { getSinisterAPI } from '../sevices/qSiseSinisterService';
import { GET_Q_SISE_SINISTER_REJECTED, GET_REQUEST_WORKFLOW_GROUP_PENDING } from './types'

export const getQsiseSinisterPending = () => {
	return {
		type: GET_REQUEST_WORKFLOW_GROUP_PENDING
	};
};

export const getQsiseSinister = (token) => async (dispatch) => {
    await dispatch(getSinisterAPI(token));
};

export const getQsiseSinisterRejected = () => {
	return {
		type: GET_Q_SISE_SINISTER_REJECTED
	};
};