import {
  GET_TRACKING_REQUEST_WORKFLOW_PENDING,
  GET_TRACKING_REQUEST_WORKFLOW_FULFILLED,
  GET_TRACKING_REQUEST_WORKFLOW_REJECTED,
  GET_TRACKING_REQUEST_WORKFLOW_BY_ID_PENDING,
  GET_TRACKING_REQUEST_WORKFLOW_BY_ID_FULFILLED,
  GET_TRACKING_REQUEST_WORKFLOW_BY_ID_REJECTED,
  ADD_TRACKING_REQUEST_WORKFLOW_PENDING,
  ADD_TRACKING_REQUEST_WORKFLOW_FULFILLED,
  ADD_TRACKING_REQUEST_WORKFLOW_REJECTED,
  UPDATE_TRACKING_REQUEST_WORKFLOW_PENDING,
  UPDATE_TRACKING_REQUEST_WORKFLOW_FULFILLED,
  UPDATE_TRACKING_REQUEST_WORKFLOW_REJECTED,
} from "../actions/types";

const initialState = {
  data: [],
  filteredData: [],
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRACKING_REQUEST_WORKFLOW_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_TRACKING_REQUEST_WORKFLOW_FULFILLED:
      return {
        ...state,
        data:
          state.data.length > 0
            ? [...state.data, ...JSON.parse(action.payload)]
            : JSON.parse(action.payload),
        loading: false,
        error: false,
      };
    case GET_TRACKING_REQUEST_WORKFLOW_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };

    case ADD_TRACKING_REQUEST_WORKFLOW_PENDING:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ADD_TRACKING_REQUEST_WORKFLOW_FULFILLED:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
        error: false,
      };
    case ADD_TRACKING_REQUEST_WORKFLOW_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_TRACKING_REQUEST_WORKFLOW_BY_ID_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };

    case GET_TRACKING_REQUEST_WORKFLOW_BY_ID_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_TRACKING_REQUEST_WORKFLOW_BY_ID_FULFILLED:
      if (action.payload && action.payload.tracking) {
        action.payload.tracking = action.payload.tracking.reverse();
      }
      return {
        ...state,
        data: [action.payload],
        loading: false,
        error: false,
      };

    case UPDATE_TRACKING_REQUEST_WORKFLOW_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATE_TRACKING_REQUEST_WORKFLOW_FULFILLED:
      const newData = state.data.map((item) => {
        if (item._id === action.payload._id) {
          if (action.payload && action.payload.tracking) {
            action.payload.tracking = action.payload.tracking.reverse();
          }
          return action.payload;
        }

        return item;
      });

      return {
        ...state,
        data: newData,
        /*data: [action.payload],*/
        loading: false,
        error: false,
      };
    case UPDATE_TRACKING_REQUEST_WORKFLOW_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
