import { createAsyncAction } from 'redux-promise-middleware-actions';
import { insuredApi, validateJSONResponse, exceptionCodeResponse } from '../utils/urls';
import { getQsiseSinisterPending, getQsiseSinisterRejected } from '../actions/qSiseSinisterAction';

export const getSinisterAPI = createAsyncAction(
    'GET_Q_SISE_SINISTER',
    async (token) => {
        const res = await fetch(`${insuredApi}v1/insured/list/sinisters`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                getQsiseSinisterPending();
                return validateJSONResponse(res)
            })
            .catch((err) => {
                getQsiseSinisterRejected();
                throw exceptionCodeResponse(err);
            });
        return res;
    }
);