import {
    GET_FISCAL_CONDITION,
    GET_FISCAL_CONDITION_FULFILLED,
    GET_FISCAL_CONDITION_PENDING,
    GET_FISCAL_CONDITION_REJECTED,
  } from '../actions/types';
  import { dataYears } from '../utils/dataSet';
  
  const initialState = {
    data: [],
    loading: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_FISCAL_CONDITION_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_FISCAL_CONDITION_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_FISCAL_CONDITION_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  }
  