import {
    POST_LEAD_PENDING,
    POST_LEAD_FULFILLED,
    POST_LEAD_REJECTED,
    UPDATE_LEAD_PENDING,
    UPDATE_LEAD_FULFILLED,
    UPDATE_LEAD_REJECTED,
    CLEAN_LEAD,
    GET_LEAD_AND_QUOTATION_PENDING,
    GET_LEAD_AND_QUOTATION_FULFILLED,
    GET_LEAD_AND_QUOTATION_REJECTED,
    GET_LEADS_PENDING,
    GET_LEADS_FULFILLED,
    GET_LEADS_REJECTED,
    FILTER_LEADS,
    RESET_LEADS,
    SORT_LEADS 
  } from '../actions/types';
  import { getFilterCondition } from '../components/filter/filterConditions';
  
  const initialState = {
    data: {},
    filteredData: [],
    loading: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_LEAD_AND_QUOTATION_FULFILLED:
        const { lead } = action.payload;
        return {
          ...state,
          data: lead,
          loading: false,
          error: false,
        };
      case GET_LEAD_AND_QUOTATION_PENDING:
      case POST_LEAD_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case POST_LEAD_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_LEAD_AND_QUOTATION_REJECTED:
      case POST_LEAD_REJECTED:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case UPDATE_LEAD_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case UPDATE_LEAD_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case UPDATE_LEAD_REJECTED:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case CLEAN_LEAD:
        return {
          ...state,
          data: {}
        };
      case GET_LEADS_PENDING:
          return {
              ...state,
              loading: true,
              error: false
          };
      case GET_LEADS_FULFILLED:
          return {
              ...state,
              data: action.payload,
              filteredData: action.payload.docs,
              totalDocs: action.payload.totalDocs,
              loading: false,
              error: false
          };
      case GET_LEADS_REJECTED:
          return {
              ...state,
              data: [],
              loading: false,
              error: true
          };
      case FILTER_LEADS: {
          const { filters, rules } = action.payload;
          const filtered = state.data.filter((d) => {
              const conditions = Object.keys(filters).map((filter) =>
                  getFilterCondition(
                      filters[filter],
                      d[rules[filter].filterKey],
                      rules[filter].comparationType
                  )
              );
              return conditions.every((c) => c);
          });
          return {
              ...state,
              // filteredData: state.data.filter((d) => moment(d.date).isBetween(moment(action.payload.filterValues.startDate), moment(action.payload.filterValues.endDate))),
              filteredData: filtered
          };
      }
      case RESET_LEADS:
          return {
              ...state,
              filteredData: state.data
          };
      case SORT_LEADS: {
          const { orderBy } = action.payload;
          const sortData = state.filteredData.sort((a, b) => {
              if (a[orderBy] > b[orderBy]) {
                  return -1;
              }
              if (a[orderBy] < b[orderBy]) {
                  return -1;
              }
              return 0;
          });
          return {
              ...state,
              filteredData: sortData
          };
      }
        default:
        return state;
    }
  }
  