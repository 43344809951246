import React, { useEffect, useRef, useState } from 'react';
import {
    Grid,
    MenuItem,
    Button,
    InputAdornment,
    IconButton,
    Tooltip,
    CircularProgress
} from '@material-ui/core';
import { Field, reduxForm, change } from 'redux-form';
import { renderTextField } from '../quoter/quoteInputs';
import { useSelector, useDispatch } from 'react-redux';
import { Search } from '@material-ui/icons';
import { getPolicyList } from '../../actions/policyActions.js';
import { useStyles } from './styles';

const validate = (values) => {
    const errors = {};
    const requiredFields = [];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

function SearcherPolicy({ handleSubmit, handleSearchResult }) {
    const [instructions, setInstructions] = useState(false);
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const config = useSelector((state) => state.userConfig.data);
    const { policy, auth } = useSelector((state) => state);
    const refPolicy = useRef(null);
    let colorPrimary = '';
    let colorSecondary = '';
    let colorSecondaryVariant = '';

    if (config.theme) {
        colorPrimary = config.theme.primary.main;
        colorSecondary = config.theme.secondary.main;
        colorSecondaryVariant = config.theme.secondary.variant5;
    }

    const handleSubmits = async (values) => {
        console.log(values);
        //handleSubmitItem(valueCodigoPostal)
    };

    const handleFocusQueryPolicy = (e) => {
        setInstructions(true);
    };
    const handleBlurQueryPolicy = (e) => {
        setInstructions(false);
    };

    const handleSearchPolicy = async () => {
        let filteredResult = [];
        const queryPolicy = refPolicy.current.value;
        if (queryPolicy) {
            const formatParams = getQueryParam(queryPolicy);
            if (formatParams.error) {
                return;
            }
            setLoading(true);
            const response = await dispatch(
                getPolicyList(auth.token, formatParams)
            );
            if (response.policyList?.result.length === 0) {
                setResult(['No se encontraron resultados']);
                setLoading(false);
                return;
            }
            if (
                formatParams.contratante ||
                formatParams.asegurado ||
                formatParams.nro_documento ||
                formatParams.nro_poliza
            ) {
                filteredResult = response.policyList?.result.map((policy) => {
                    return {
                        asegurado: policy.asegurado,
                        numeroPoliza: policy.nro_poliza,
                        contratante: policy.contratante,
                        txt_desc: policy.txt_desc,
                        status: policy.estado
                    };
                });
                console.log(filteredResult);
                setResult(filteredResult);
                handleSearchResult(filteredResult);
                setLoading(false);
            } else if (formatParams.placa) {
                filteredResult = response.policyList?.result.map((policy) =>
                    console.log(policy)
                );
                // return {
                //     placa: policy.placa,
                //     marca: policy.marca,
                //     modelo: policy.modelo,
                //     policy_number: policy.policy_number,
                //     numeroPoliza: policy.nro_poliza,
                //     contratante: policy.contratante,
                //     txt_desc: policy.txt_desc,
                //     status: policy.estado,
                // }
                setResult(filteredResult);
                handleSearchResult(filteredResult);
                setLoading(false);
            }
        }
    };

    const getQueryParam = (queryPolicy) => {
        const queryParams = queryPolicy.split(' ');
        const characters = ['t', 'T', 'p', 'P', 'd', 'D'];
        const formatParams = {};
        if (queryParams[0].length === 1 && queryParams.length >= 2) {
            if (characters.includes(queryParams[0])) {
                if (['t', 'T'].includes(queryParams[0])) {
                    formatParams.contratante = queryPolicy.slice(2);
                } else if (['p', 'P'].includes(queryParams[0])) {
                    formatParams.placa = queryPolicy.slice(2);
                } else if (['n', 'N'].includes(queryParams[0])) {
                    formatParams.asegurado = queryPolicy.slice(2);
                } else if (['d', 'D'].includes(queryParams[0])) {
                    formatParams.nro_documento = queryPolicy.slice(2);
                } else {
                    formatParams.error = 'No es una consulta valida';
                }
            } else {
                formatParams.error = 'No es una consulta valida';
            }
        } else if (queryParams.length >= 1) {
            if (!isNaN(queryParams[0])) {
                formatParams.nro_poliza = queryParams[0];
            } else {
                formatParams.error = 'No es una consulta valida';
            }
        } else {
            formatParams.error = 'No es una consulta valida';
        }
        return formatParams;
    };

    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Grid container direction="row">
                <Grid
                    item
                    xs={12}
                    md={8}
                    style={{ padding: 8, position: 'relative' }}
                >
                    <Field
                        ref={refPolicy}
                        name="queryPolicy"
                        label="Buscar Póliza"
                        component={renderTextField}
                        onFocus={handleFocusQueryPolicy}
                        onBlur={handleBlurQueryPolicy}
                        custom={{ tabIndex: 6 }}
                        autocomplete="off"
                        endAdornment={
                            <InputAdornment position="end">
                                <Tooltip
                                    title="Buscar por nombre"
                                    arrow
                                    placement="top"
                                >
                                    <IconButton
                                        aria-label="check input value"
                                        style={{
                                            color: colorPrimary
                                        }}
                                        onClick={handleSearchPolicy}
                                    >
                                        <Search />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }
                    />
                    {instructions && (
                        <div className={classes.instructionsContainer}>
                            <p>Opciones de búsqueda:</p>
                            <p>"Número de póliza" ó</p>
                            <p>"t nombre tomador" ó</p>
                            <p>"p número de placa" ó</p>
                            <p>"d nombre de un asegurado"</p>
                        </div>
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ padding: 8, position: 'relative' }}
                >
                    {loading && (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                    {/* {
                        result.map((policy,i) => (
                            <p key={i}>
                                - {policy.asegurado}
                            </p>
                        ))
                    } */}
                </Grid>
            </Grid>
        </form>
    );
}

export default reduxForm({ form: 'searcherPolicy', validate })(SearcherPolicy);
